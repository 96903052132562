import React, { Component } from "react";
import Modal from "react-bootstrap-modal";
import MarketShareMultiLineChart from "../../Component/MarketShareMultiLineChart";
import "../../Component/component.scss";

class MarketShareCustomHeaderGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartVisible: false,
    };
  }

  closeChartModal = () => {
    this.setState({ chartVisible: false });
  };

  renderMultilineChart() {
    const section = this.props.columnApi.columnController.columnDefs[0].children[0].field;
    const getAirline = this.props.agGridReact.props.parentStates !== null && this.props.agGridReact.props.parentStates != undefined ? this.props.agGridReact.props.parentStates.getAirline : ''
    
    const period = this.props.agGridReact.props.parentStates !== null && this.props.agGridReact.props.parentStates != undefined ? this.props.agGridReact.props.parentStates.period : ''
    let rangeValue = JSON.parse(window.localStorage.getItem("rangeValue"));
    let duration = ' Year-wise data';
    if (period !=undefined && period !==null){
      if (period === 'monthly'){
        duration = ' from Jan ' + rangeValue.from.year + ' - Dec ' + rangeValue.from.year
      }
      if (period === 'quarterly'){
        duration = ' for all quarters of ' + rangeValue.from.year
      }
    }
    return (
      <Modal
        show={this.state.chartVisible}
        onHide={this.closeChartModal}
        aria-labelledby="ModalHeader"
      >
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">{this.props.displayName}{duration}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MarketShareMultiLineChart
            section={section}
            displayName={this.props.displayName}
            getAirline={getAirline}
            period={period}
          />
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <div className="ag-header-group-cell-label">
          <div className="customHeaderLabel">{this.props.displayName}</div>
          <i
            className="fa fa-bar-chart-o"
            style={{ marginLeft: "10px", cursor: "pointer" }}
            onClick={() => this.setState({ chartVisible: true })}
          ></i>
        </div>
        {this.renderMultilineChart()}
      </div>
    );
  }
}

export default MarketShareCustomHeaderGroup;
