import React, { Component } from "react";
import APIServices from "../../API/apiservices";
import eventApi from "../../API/eventApi";
// import { Link } from "react-router-dom";
import api from "../../API/api";
import AlertModal from "../../Component/AlertModal";
import InfareMultiLineChart from "../../Component/InfareMultiLineChart";
import RBDModal from "../../Component/RBDModal";
import DataTableModal from "../../Component/DataTableModal";
import Loader from "../../Component/Loader";
import cookieStorage from "../../Constants/cookie-storage";
import String from "../../Constants/validator";
import URLSearchParams from "../../Constants/validator";
import Access from "../../Constants/accessValidation";
import DownloadCSV from "../../Component/DownloadCSV";
import DataTableComponent from "../../Component/DataTableComponent";
import TotalRow from "../../Component/TotalRow";
import { string } from "../../Constants/string";
import color from "../../Constants/color";
import Constant from "../../Constants/validator";
import $ from "jquery";
import "../../App";
import "./index.scss";
import TopMenuBar from "../../Component/TopMenuBar";

import Modal from "react-bootstrap-modal";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import BrowserToProps from "react-browser-to-props";
import Swal from "sweetalert2";
import { result } from "lodash";
import DatePicker from "../../Component/DatePicker";
import { Link } from "react-router-dom";
// import MarketShareGraph from "./MarketShareGraph";
// import POSDetail from "../PosDetails";

const apiServices = new APIServices();

const currentYear = new Date().getFullYear();
let monthData = [];
let bcData = [];
const rangeValue = {
  from: { year: new Date().getFullYear(), month: 1 },
  to: { year: new Date().getFullYear(), month: new Date().getMonth() + 1 },
};

export const CustomDropdown = (props) => (
  <div className="form-group">
    <strong>{props.AirlineName}</strong>
    <select
      className="form-control cabinselect pos-route-dropdown"
      name="{props.AirlineName}"
      onChange={props.onChange}
    >
      <option defaultValue>Select {props.name}</option>
      {props.options.map((item, index) => (
        <option key={index} value={item.id}>
          {item.AirlineName}
        </option>
      ))}
    </select>
  </div>
);

class MarketShareRoute extends Component {
  constructor(props) {
    super(props);
    this.selectedRegion = null;
    this.selectedCountry = null;
    this.selectedCity = null;
    this.monthURL = "";
    this.dayURL = "";
    this.drilldownURL = "";
    this.state = {
      posMonthDetails: [],
      monthTotalData: [],
      drillDownTotalData: [],
      monthcolumns: [],
      drillDownColumn: [],
      drillDownData: [],
      modaldrillDownColumn: [],
      modaldrillDownData: [],
      modalData: [],
      modalCompartmentData: [],
      modalCompartmentColumn: [],
      availModalData: [],
      availModalColumn: [],
      availTotalData: [],
      availModalVisible: false,
      tableDatas: true,
      gettingMonth: null,
      gettingYear: null,
      gettingDay: 1,
      gettingRegion: "Null",
      monthTableTitle: "NETWORK",
      tabLevel: "Null",
      cabinOption: [],
      monthRange: [],
      alertData: [],
      getCabinValue: [],
      getAirline: "Null",
      airlineCollection: [],
      period: "monthly",
      getGraphPeriod: "monthly",
      cityForGraph: "*",
      startDate: this.formatDate(
        new Date(rangeValue.from.year, rangeValue.from.month - 1, 1)
      ),
      endDate: this.formatDate(
        new Date(rangeValue.to.year, rangeValue.to.month, 0)
      ),
      cabinSelectedDropDown: [],
      cabinDisable: false,
      toggle: "bc",
      alertVisible: false,
      tableModalVisible: false,
      tabName: "Region",
      regionId: "*",
      countryId: "*",
      cityId: "*",
      commonOD: "*",
      type: "Null",
      baseAccess: "",
      selectedData: "Null",
      infareData: [],
      infareColumn: [],
      infareModalVisible: false,
      marketShareModalVisible: false,
      infareGraphHeading: "",
      loading: false,
      loading2: false,
      loading3: false,
      firstLoadList: false,
      show_LL2Y_Rows: false,
      show_LLY_Rows: false,
      showLastYearRows: false,
      showNextYearRows: false,
      accessLevelDisable: false,
      posMonthRowClassRule: {
        "highlight-row": "data.highlightMe",
      },
      firstHome: true,
      infareCurrency: "",
      outerTab: false,
      ancillary: false,
      ensureIndexVisible: null,
      dayLevel: false,
      disableDropDown: false,
      odsearchvalue: "",
      rangeValuePicker: rangeValue,
    };
    this.sendEvent(
      "1",
      "viewed MarketShare Page",
      "marketShare",
      "MarketShare"
    );
    this.pathName = window.location.pathname;
  }

  sendEvent = (id, description, path, page_name) => {
    var eventData = {
      event_id: `${id}`,
      description: `User ${description}`,
      where_path: `/${path}`,
      page_name: `${page_name}`,
    };
    eventApi.sendEvent(eventData);
  };

  componentWillMount() {
    var self = this;
    const ancillary = URLSearchParams.getParameterByName(
      "ancillary",
      window.location.href
    );
    const isDaylevel = self.pathName === "/posDay" ? true : false;
    const cabinData = JSON.parse(window.localStorage.getItem("classData"));
    const monthRange = JSON.parse(
      window.localStorage.getItem("monthRangeDropdown")
    );
    this.setState({
      ancillary: ancillary ? ancillary : false,
      dayLevel: isDaylevel,
      cabinOption: cabinData,
      monthRange: monthRange,
      disableDropDown: !Access.accessValidation("Dashboard", "routeDashboard")
        ? true
        : false,
    });
    self.getFiltersValue();
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {
      const obj = this.props.browserToProps.queryParams;
      let data = Object.values(obj);
      let title = Object.keys(obj);
      const lastIndex = title.length - 1;
      if (data[0] !== "undefined") {
        this.pushURLToBcData(obj, title, data, lastIndex);
        this.setState({ firstHome: true });
      } else {
        if (this.state.firstHome) {
          this.homeHandleClick();
        }
      }
    };
  }

  pushURLToBcData(obj, title, data, lastIndex) {
    const self = this;
    let region = [];
    let country = [];
    let city = [];

    if (
      obj.hasOwnProperty("Region") &&
      !bcData.some(function (o) {
        return o["title"] === "Region";
      })
    ) {
      let data = obj["Region"];
      let bcContent = obj["Region"];
      let multiSelectLS;
      let regionId;

      if (data.includes(",")) {
        data = `'${data.split(",").join("','")}'`;
      } else if (
        data.charAt(0) !== "'" &&
        data.charAt(data.length - 1) !== "'"
      ) {
        data = `'${data}'`;
      }

      if (
        bcContent.charAt(0) === "'" &&
        bcContent.charAt(bcContent.length - 1) === "'"
      ) {
        regionId = bcContent.substring(1, bcContent.length - 1);
      } else if (bcContent.includes(",")) {
        multiSelectLS = bcContent.split(",");
        regionId = bcContent;
      }
      // else if(bcContent.includes("','")){
      //   multiSelectLS = bcContent.split("','");
      //   regionId = bcContent;
      // }
      else {
        regionId = bcContent;
      }
      bcData.push({ val: regionId, title: "Region" });
      self.setState({ regionId: data });
      let regionLS = bcContent.includes(",")
        ? multiSelectLS
        : region.concat([regionId]);
      window.localStorage.setItem("RegionSelected", JSON.stringify(regionLS));
    }
    if (
      obj.hasOwnProperty("Country") &&
      !bcData.some(function (o) {
        return o["title"] === "Country";
      })
    ) {
      let data = obj["Country"];
      let bcContent = decodeURIComponent(obj["Country"]);
      let multiSelectLS;
      let countryId;

      if (data.includes(`','`)) {
        data = `'${data}'`;
      } else if (data.includes(",")) {
        data = `'${data.split(",").join("','")}'`;
      } else if (
        data.charAt(0) !== "'" &&
        data.charAt(data.length - 1) !== "'"
      ) {
        data = `'${data}'`;
      }

      self.setState({ countryId: data });

      if (
        bcContent.charAt(0) === "'" &&
        bcContent.charAt(bcContent.length - 1) === "'"
      ) {
        countryId = bcContent.substring(1, bcContent.length - 1);
      } else if (bcContent.includes(`','`)) {
        multiSelectLS = bcContent.split(`','`);
        countryId = bcContent.split(`','`).join(",");
      } else if (bcContent.includes(",")) {
        multiSelectLS = bcContent.split(",");
        countryId = bcContent;
      } else {
        countryId = bcContent;
      }
      bcData.push({ val: countryId, title: "Country" });
      let countryLS = bcContent.includes(",")
        ? multiSelectLS
        : country.concat([countryId]);
      window.localStorage.setItem(
        "RouteCountrySelected",
        JSON.stringify(countryLS)
      );
    }
    if (
      obj.hasOwnProperty("POS") &&
      !bcData.some(function (o) {
        return o["title"] === "POS";
      })
    ) {
      let data = obj["POS"];
      let bcContent = obj["POS"];
      let multiSelectLS;
      let cityId;

      if (data.includes(",")) {
        data = `'${data.split(",").join("','")}'`;
      } else if (
        data.charAt(0) !== "'" &&
        data.charAt(data.length - 1) !== "'"
      ) {
        data = `'${data}'`;
      }
      if (
        bcContent.charAt(0) === "'" &&
        bcContent.charAt(bcContent.length - 1) === "'"
      ) {
        cityId = bcContent.substring(1, bcContent.length - 1);
      } else if (bcContent.includes(",")) {
        multiSelectLS = bcContent.split(",");
        cityId = bcContent;
      } else {
        cityId = bcContent;
      }

      bcData.push({ val: cityId, title: "POS" });
      self.setState({ cityId: data });
      let cityLS = bcContent.includes(",")
        ? multiSelectLS
        : city.concat([cityId]);
      // window.localStorage.setItem("CitySelected", JSON.stringify(cityLS));
    }
    if (
      obj.hasOwnProperty("O%26D") &&
      !bcData.some(function (o) {
        return o["title"] === "O&D";
      })
    ) {
      bcData.push({ val: obj["O%26D"], title: "O&D" });

      self.setState({ commonOD: obj["O%26D"] });
      window.localStorage.setItem("ODSelected", obj["O%26D"]);
    }

    if (bcData.length > 0) {
      var removeArrayIndex = bcData.slice(0, lastIndex + 1);
      bcData = removeArrayIndex;
    }
    
    this.listHandleClick(data[lastIndex], title[lastIndex], "browserBack");
  }

  // period = (e) => {
  //   let target = e.target.value;
  //   let { regionId, countryId, cityId } = this.state;

  //   this.setState({ period: target }, () =>
  //     this.getMarketShareMonthDrillDownData(regionId, countryId, cityId)
  //   );
  // };

  handleRangeDissmis = (value) => {
    let { regionId, countryId, cityId } = this.state;
    let startDate = this.formatDate(
      new Date(value.from.year, value.from.month - 1, 1)
    );
    let endDate = this.formatDate(new Date(value.to.year, value.to.month, 0));
    this.setState(
      {
        rangeValuePicker: value,
        startDate: startDate,
        endDate: endDate,
      },
      () => this.getMarketShareMonthDrillDownData(regionId, countryId, cityId)
    );
    window.localStorage.setItem("rangeValue", JSON.stringify(value));
  };

  formatDate = (d) => {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  getFiltersValue = () => {
    bcData = [];
    let RegionSelected = window.localStorage.getItem("RegionSelected");
    let CountrySelected = window.localStorage.getItem("CountrySelected");
    let CitySelected = window.localStorage.getItem("CitySelected");
    let rangeValue = JSON.parse(window.localStorage.getItem("rangeValue"));
    let getCabinValue = window.localStorage.getItem("CabinSelected");
    let ODSelected = window.localStorage.getItem("ODSelected");
    let startDate = "";
    let endDate = "";

    if (rangeValue !== null) {
      startDate = this.formatDate(
        new Date(rangeValue.from.year, rangeValue.from.month - 1, 1)
      );
      endDate = this.formatDate(
        new Date(rangeValue.to.year, rangeValue.to.month, 0)
      );
      this.setState({ rangeValuePicker: rangeValue });
    }
    startDate = startDate === "" ? this.state.startDate : startDate;
    endDate = endDate === "" ? this.state.endDate : endDate;

    // let getAirline = window.localStorage.getItem("getAirline");
    // let period = window.localStorage.getItem("period");

    let cabinSelectedDropDown =
      getCabinValue === null || getCabinValue === "Null"
        ? []
        : JSON.parse(getCabinValue);
    getCabinValue =
      cabinSelectedDropDown.length > 0 ? cabinSelectedDropDown : "Null";

    CitySelected =
      CitySelected === null || CitySelected === "Null" || CitySelected === ""
        ? "*"
        : JSON.parse(CitySelected);

    // getAirline =
    //   getAirline === null || getAirline === "Null" || getAirline === ""
    //     ? "Null"
    //     : JSON.parse(getAirline);

    // period =
    //   period === null || period === "Null" || period === "monthly"
    //     ? "monthly"
    //     : JSON.parse(period);

    this.setState(
      {
        regionId:
          RegionSelected === null ||
          RegionSelected === "Null" ||
          RegionSelected === ""
            ? "*"
            : JSON.parse(RegionSelected),
        // getAirline:
        //   getAirline === null || getAirline === "Null" || getAirline === ""
        //     ? "Null"
        //     : JSON.parse(getAirline),

        // period:
        //   period === null || period === "Null" || period === "monthly"
        //     ? "monthly"
        //     : JSON.parse(period),
        countryId:
          CountrySelected === null ||
          CountrySelected === "Null" ||
          CountrySelected === ""
            ? "*"
            : JSON.parse(CountrySelected),
        cityId: CitySelected,
        commonOD:
          ODSelected === null ||
          ODSelected === "Null" ||
          ODSelected === "" ||
          CitySelected === "*"
            ? "*"
            : `'${ODSelected}'`,
        gettingMonth: window.monthNumToName(rangeValue.from.month),
        gettingYear: rangeValue.from.year,
        getCabinValue: getCabinValue,
        cabinSelectedDropDown: cabinSelectedDropDown,
        startDate: startDate,
        endDate: endDate,
        // getAirline: getAirline,
        // period: period,
      },
      () => this.getInitialData()
    );
  };

  getInitialData = () => {
    var self = this;
    let { regionId, countryId, cityId, commonOD } = this.state;
    self.setState({
      loading: true,
      loading2: true,
      firstLoadList: true,
      posMonthDetails: [],
      monthTotalData: [],
      drillDownData: [],
      drillDownTotalData: [],
    });

    self.getInitialListData(regionId, countryId, cityId, commonOD);

    self.getMarketShareMonthDrillDownData(
      regionId,
      countryId,
      cityId,
      commonOD,
      "initial"
    );
  };

  getInitialListData = (regionId, countryId, cityId, OD) => {
    const self = this;
    let commonOD = OD.substring(1, OD.length - 1);

    /*if (Constant.getAccessStatus().hasAccess) {
          this.setState({ accessLevelDisable: true })
        }*/

    if (regionId !== "*") {
      bcData.push({ val: regionId, title: "Region" });
      self.setState({ selectedData: regionId });
    }
    if (countryId !== "*") {
      bcData.push({ val: countryId, title: "Country" });
      self.setState({ selectedData: countryId });
    }
    if (cityId !== "*") {
      bcData.push({ val: cityId, title: "POS" });
      self.setState({ selectedData: cityId });
    }
    if (cityId !== "*") {
      if (commonOD !== "*") {
        bcData.push({ val: commonOD, title: "O&D" });
        self.setState({ selectedData: OD });
      }
    }
  };

  getMarketShareMonthDrillDownData = (
    regionId,
    countryId,
    cityId,
    commonOD,
    initial
  ) => {
    var self = this;
    let {
      gettingMonth,
      // getCabinValue,
      type,
      gettingYear,
      toggle,
      gettingDay,
      dayLevel,
      ancillary,
      odsearchvalue,
      getAirline,
      period,
      startDate,
      endDate,
    } = this.state;

    self.setState({
      loading: true,
      loading2: true,
      posMonthDetails: [],
      monthTotalData: [],
      drillDownData: [],
      drillDownTotalData: [],
    });

    if (dayLevel) {
      apiServices
        .getPOSDayTables(
          toggle,
          gettingYear,
          gettingMonth,
          regionId,
          countryId,
          cityId,
          commonOD
          // getCabinValue
        )
        .then(function (result) {
          self.setState({ loading: false, firstLoadList: false });
          if (result) {
            var totalData = result[0].totalData;
            var columnName = result[0].columnName;
            var posDaydata = result[0].rowData;
            self.dayURL = result[0].url;
            self.setState({
              posMonthDetails: self.getHighlightedDay(posDaydata, gettingDay),
              monthcolumns: columnName,
              monthTotalData: totalData,
            });
          }
        });
    } else {
      apiServices
        .getMarketShareRouteMonthTables(
          //   toggle,
          //   regionId,
          //   countryId,
          //   cityId,
          //   commonOD,
          //   getCabinValue,
          //   getAirline,

          startDate,
          endDate
        )
        .then(function (result) {
          self.setState({ loading: false, firstLoadList: false });
          if (result) {
            var totalData = result[0].totalData;
            var columnName = result[0].columnName;
            var posMonthdata = result[0].rowData;
            self.monthURL = result[0].url;
            monthData = posMonthdata;
            self.setState({
              posMonthDetails: self.getHighlightedMonth(
                posMonthdata,
                gettingMonth,
                gettingYear
              ),
              // posMonthDetails: posMonthdata,
              monthcolumns: columnName,
              monthTotalData: totalData,
              startDate,
              endDate,
            });
          }
        });
    }

    let selectedType = "";
    if (ancillary && initial) {
      selectedType = "Ancillary";
      self.setState({ type: "Ancillary" });
    } else {
      selectedType = type;
    }

    apiServices
      .getMarketShareRouteDrillDownData(
        // gettingYear,
        // toggle,
        // gettingMonth,
        // regionId,
        // countryId,
        // cityId,
        // commonOD,
        // getCabinValue,

        // dayLevel,
        // gettingDay,
        // odsearchvalue
        // selectedType,
        // getAirline,

        gettingMonth,
        gettingYear
      )

      .then((result) => {
        self.setState({ loading2: false });
        if (result) {
          self.drilldownURL = result[0].url;
          let countryId = result[0].currentAccess.countryId;
          self.setState({
            drillDownTotalData: result[0].totalData,
            drillDownData: result[0].rowData,
            drillDownColumn: self.addCellRender(result[0].columnName),
            tabName:
              selectedType === "Null"
                ? result[0].tabName
                : result[0].firstTabName,
            regionId: result[0].currentAccess.regionId,
            // countryId:
            //   countryId === "*"
            //     ? countryId
            //     : Constant.getFullCountryName(countryId),
            // cityId: result[0].currentAccess.cityId,
            // getAirline: getAirline,
            // period: period,
            gettingMonth: gettingMonth,
            gettingYear: gettingYear,
            // startDate: startDate,
            // endDate: endDate,
            // commonOD: result[0].currentAccess.commonOD,
          });
        }
      });
  };

  getDrillDownData = (regionId, countryId, cityId, commonOD, type) => {
    var self = this;
    let {
      // gettingYear,
      // gettingMonth,
      // getCabinValue,
      // toggle,
      // dayLevel,
      // gettingDay,
      // odsearchvalue,
      gettingMonth,
      gettingYear,
    } = this.state;

    apiServices
      .getMarketShareRouteDrillDownData(
        // gettingYear,
        // toggle,
        // gettingMonth,
        // regionId,
        // countryId,
        // cityId,
        // commonOD,
        // getCabinValue,
        // type,
        // dayLevel,
        // gettingDay,
        // odsearchvalue
        gettingMonth,
        gettingYear
      )
      .then((result) => {
        self.setState({ loading2: false });
        if (result) {
          self.drilldownURL = result[0].url;
          let countryId = result[0].currentAccess.countryId;
          self.setState({
            drillDownTotalData: result[0].totalData,
            drillDownData: result[0].rowData,
            drillDownColumn: self.addCellRender(result[0].columnName),
            tabName:
              type === "Null" ? result[0].tabName : result[0].firstTabName,
            // regionId: result[0].currentAccess.regionId,
            // countryId:
            //   countryId === "*"
            //     ? countryId
            //     : Constant.getFullCountryName(countryId),
            // cityId: result[0].currentAccess.cityId,
            // getAirline: getAirline,
            // period: period,
            // startDate: startDate,
            // endDate: endDate,

            // commonOD: result[0].currentAccess.commonOD,
            gettingMonth,
            gettingYear,
          });
        }
      });
  };

  getHighlightedDay(posDaydata, getday) {
    let data = posDaydata.filter((data, index) => {
      var day = data.Date;
      if (getday === day) {
        data.highlightMe = true;
        this.setState({ ensureIndexVisible: index });
      }
      return data;
    });
    return data;
  }

  getHighlightedMonth(posMonthdata, month, year) {
    let count = 0;
    let data = posMonthdata.filter((data, index) => {
      var monthName = data.Month;
      if (year !== currentYear - 1) {
        if (data.Year === currentYear - 1) {
          count = count + 1;
        }
      }
      if (Constant.selectedMonthWithYear(month, year) === monthName) {
        data.highlightMe = true;
        this.setState({ ensureIndexVisible: index - count });
      }
      if (year === currentYear) {
        if (data.Month === `Total ${currentYear - 3}`) {
          data.Month = `► Total ${currentYear - 3}`;
        }
        if (data.Month === `Total ${currentYear - 2}`) {
          data.Month = `► Total ${currentYear - 2}`;
        }
        if (data.Month === `Total ${currentYear - 1}`) {
          data.Month = `► Total ${currentYear - 1}`;
        }
        if (data.Month === `Total ${currentYear + 1}`) {
          data.Month = `► Total ${currentYear + 1}`;
        }
        if (
          data.Year === currentYear ||
          data.Month === `► Total ${currentYear - 3}` ||
          data.Month === `► Total ${currentYear - 2}` ||
          data.Month === `► Total ${currentYear - 1}` ||
          data.Month === `► Total ${currentYear + 1}`
        ) {
          return data;
        }
      } else if (year === currentYear + 1) {
        if (data.Month === `Total ${currentYear - 3}`) {
          data.Month = `► Total ${currentYear - 3}`;
        }
        if (data.Month === `Total ${currentYear - 2}`) {
          data.Month = `► Total ${currentYear - 2}`;
        }
        if (data.Month === `Total ${currentYear - 1}`) {
          data.Month = `► Total ${currentYear - 1}`;
        }
        if (data.Month === `Total ${currentYear + 1}`) {
          data.Month = `▼ Total ${currentYear + 1}`;
        }
        if (
          data.Year >= currentYear ||
          data.Month === `► Total ${currentYear - 3}` ||
          data.Month === `► Total ${currentYear - 2}` ||
          data.Month === `► Total ${currentYear - 1}` ||
          data.Month === `▼ Total ${currentYear + 1}`
        ) {
          return data;
        }
        this.setState({
          show_LL2Y_Rows: false,
          show_LLY_Rows: false,
          showNextYearRows: true,
          showLastYearRows: false,
        });
      } else if (year === currentYear - 1) {
        if (data.Month === `Total ${currentYear - 3}`) {
          data.Month = `► Total ${currentYear - 3}`;
        }
        if (data.Month === `Total ${currentYear - 2}`) {
          data.Month = `► Total ${currentYear - 2}`;
        }
        if (data.Month === `Total ${currentYear - 1}`) {
          data.Month = `▼ Total ${currentYear - 1}`;
        }
        if (data.Month === `Total ${currentYear + 1}`) {
          data.Month = `► Total ${currentYear + 1}`;
        }
        if (
          data.Year === currentYear - 1 ||
          data.Year === currentYear ||
          data.Month === `► Total ${currentYear - 3}` ||
          data.Month === `► Total ${currentYear - 2}` ||
          data.Month === `▼ Total ${currentYear - 1}` ||
          data.Month === `► Total ${currentYear + 1}`
        ) {
          return data;
        }
        this.setState({
          show_LL2Y_Rows: false,
          show_LLY_Rows: false,
          showLastYearRows: true,
          showNextYearRows: false,
        });
      } else if (year === currentYear - 2) {
        if (data.Month === `Total ${currentYear - 3}`) {
          data.Month = `► Total ${currentYear - 3}`;
        }
        if (data.Month === `Total ${currentYear - 2}`) {
          data.Month = `▼ Total ${currentYear - 2}`;
        }
        if (data.Month === `Total ${currentYear - 1}`) {
          data.Month = `► Total ${currentYear - 1}`;
        }
        if (data.Month === `Total ${currentYear + 1}`) {
          data.Month = `► Total ${currentYear + 1}`;
        }
        if (
          data.Year === currentYear - 2 ||
          data.Year === currentYear ||
          data.Month === `► Total ${currentYear - 3}` ||
          data.Month === `▼ Total ${currentYear - 2}` ||
          data.Month === `► Total ${currentYear - 1}` ||
          data.Month === `► Total ${currentYear + 1}`
        ) {
          return data;
        }
        this.setState({
          show_LL2Y_Rows: false,
          show_LLY_Rows: true,
          showLastYearRows: false,
          showNextYearRows: false,
        });
      } else if (year === currentYear - 3) {
        if (data.Month === `Total ${currentYear - 3}`) {
          data.Month = `▼ Total ${currentYear - 3}`;
        }
        if (data.Month === `Total ${currentYear - 2}`) {
          data.Month = `► Total ${currentYear - 2}`;
        }
        if (data.Month === `Total ${currentYear - 1}`) {
          data.Month = `► Total ${currentYear - 1}`;
        }
        if (data.Month === `Total ${currentYear + 1}`) {
          data.Month = `► Total ${currentYear + 1}`;
        }
        if (
          data.Year === currentYear - 3 ||
          data.Year === currentYear ||
          data.Month === `▼ Total ${currentYear - 3}` ||
          data.Month === `► Total ${currentYear - 2}` ||
          data.Month === `► Total ${currentYear - 1}` ||
          data.Month === `► Total ${currentYear + 1}`
        ) {
          return data;
        }
        this.setState({
          show_LL2Y_Rows: true,
          show_LLY_Rows: false,
          showLastYearRows: false,
          showNextYearRows: false,
        });
      }
    });
    return data;
  }

  getHighlightedRow(updatedData, month) {
    let data = updatedData.map((data, index) => {
      let monthName = data.Month;
      if (
        monthName === `▼ Total ${currentYear - 1}` ||
        monthName === `► Total ${currentYear - 1}`
      ) {
        data.highlightMe = true;
      } else if (
        monthName === `▼ Total ${currentYear + 1}` ||
        monthName === `► Total ${currentYear + 1}`
      ) {
        data.highlightMe = true;
      } else if (
        monthName === `▼ Total ${currentYear - 2}` ||
        monthName === `► Total ${currentYear - 2}`
      ) {
        data.highlightMe = true;
      } else if (
        monthName === `▼ Total ${currentYear - 3}` ||
        monthName === `► Total ${currentYear - 3}`
      ) {
        data.highlightMe = true;
      }
      return data;
    });
    return data;
  }

  monthWiseCellClick = (params) => {
    
    var monththis = this;
    monththis.sendEvent("2", "clicked on Months row", "pos", "Pos Page");
    let {
      regionId,
      countryId,
      cityId,
      commonOD,
      gettingYear,
      gettingMonth,
      type,
    } = this.state;
    let selectedMonth = params.data.Month;
    let selectedYear = params.data.Year;
    var column = params.colDef.field;

    const posMonthDetails = this.state.posMonthDetails.map((d) => {
      d.highlightMe = false;
      return d;
    });
    params.api.updateRowData({ update: posMonthDetails });

    //Getting Clubbed Data

    if (selectedMonth.includes(`Total ${currentYear - 1}`)) {
      this.setState(
        {
          showLastYearRows: !this.state.showLastYearRows,
          showNextYearRows: false,
          show_LL2Y_Rows: false,
          show_LLY_Rows: false,
        },
        () =>
          this.getLastYearClubbedData(
            this.state.showLastYearRows,
            selectedMonth
          )
      );
    } else if (selectedMonth.includes(`Total ${currentYear + 1}`)) {
      this.setState(
        {
          showNextYearRows: !this.state.showNextYearRows,
          showLastYearRows: false,
          show_LL2Y_Rows: false,
          show_LLY_Rows: false,
        },
        () =>
          this.getNextYearClubbedData(
            this.state.showNextYearRows,
            selectedMonth
          )
      );
    } else if (selectedMonth.includes(`Total ${currentYear - 2}`)) {
      this.setState(
        {
          showNextYearRows: false,
          showLastYearRows: false,
          show_LL2Y_Rows: false,
          show_LLY_Rows: !this.state.show_LLY_Rows,
        },
        () => this.getLLYClubbedData(this.state.show_LLY_Rows, selectedMonth)
      );
    } else if (selectedMonth.includes(`Total ${currentYear - 3}`)) {
      this.setState(
        {
          showNextYearRows: false,
          showLastYearRows: false,
          show_LL2Y_Rows: !this.state.show_LL2Y_Rows,
          show_LLY_Rows: false,
        },
        () => this.getLL2YClubbedData(this.state.show_LL2Y_Rows, selectedMonth)
      );
    }
    // else if (dayLevel) {
    //   monththis.setState({ gettingDay: params.data.Date });
    // }
    else {
      monththis.setState({
        gettingMonth: params.data.MonthFullName,
        gettingYear: params.data.Year,
      });
      
      const range = { from: { year: params.data.Year, month: window.monthNameToNum(params.data.MonthFullName) }, to: { year: params.data.Year, month: window.monthNameToNum(params.data.MonthFullName) } }
      window.localStorage.setItem('rangeValue', JSON.stringify(range))
      
      // window.localStorage.setItem("rangeValue", JSON.stringify(range));
    }

    if (
      (column === "Month" || column === "Year") &&
      !selectedMonth.includes("Total")
    ) {
      monththis.setState({
        loading2: true,
        drillDownData: [],
        drillDownTotalData: [],
      });
      monththis.getDrillDownData(regionId, countryId, cityId, commonOD, type);
    }

    // monththis.setState({
    //   gettingMonth: params.data.MonthFullName,
    //   gettingYear: params.data.Year,
    // });
    // const range = {
    //   from: {
    //     year: params.data.Year,
    //     month: window.monthNameToNum(params.data.MonthFullName),
    //   },
    //   to: {
    //     year: params.data.Year,
    //     month: window.monthNameToNum(params.data.MonthFullName),
    //   },
    // };
    // window.localStorage.setItem("rangeValue", JSON.stringify(range));
    // }

    // if (
    //   column === "Month" ||
    //   column === "Year"
    //   // &&
    //   // !selectedMonth.includes("Total")
    // ) {
    //   monththis.setState({
    //     loading2: true,
    //     drillDownData: [],
    //     drillDownTotalData: [],
    //   });
    //   monththis.getDrillDownData(regionId, countryId, cityId, commonOD, type);
    // } else {
    //   monththis.setState({
    //     loading2: true,
    //     drillDownData: [],
    //     drillDownTotalData: [],
    //   });
    //   monththis.getDrillDownData(regionId, countryId, cityId, commonOD, type);
    // }
  };

  getLLYClubbedData(show_LLY_Rows, selectedMonth) {
    if (show_LLY_Rows) {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `► Total ${currentYear - 2}`) {
          d.Month = `▼ Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }
        if (
          d.Year === (currentYear - 2) ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `▼ Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    } else {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }

        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === currentYear ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    }
  }
  getLL2YClubbedData(show_LL2Y_Rows, selectedMonth) {
    if (show_LL2Y_Rows) {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `► Total ${currentYear - 3}`) {
          d.Month = `▼ Total ${currentYear - 3}`;
        }
        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }
        if (
          d.Year === (currentYear - 3) ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `▼ Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    } else {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }

        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === currentYear ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    }
  }

  getLastYearClubbedData(showLastYearRows, selectedMonth) {
    if (showLastYearRows) {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `► Total ${currentYear - 1}`) {
          d.Month = `▼ Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === (currentYear - 1) ||
          d.Month === `▼ Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    } else {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === currentYear ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    }
  }

  getNextYearClubbedData(showNextYearRows, selectedMonth) {
    if (showNextYearRows) {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `► Total ${currentYear + 1}`) {
          d.Month = `▼ Total ${currentYear + 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === (currentYear + 1) ||
          d.Month === `▼ Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    } else {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === currentYear ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    }
  }

  regionCellClick = (params) => {
    var self = this;
    self.sendEvent(
      "2",
      "clicked on Region drill down",
      "marketShare",
      "MarketShare Page"
    );
    let { regionId, countryId, cityId, commonOD, getCabinValue } = this.state;

    var column = params.colDef.field;
    var headName = params.colDef.headerName;
    var selectedData = `'${params.data.firstColumnName}'`;
    var selectedDataWQ = params.data.firstColumnName;
    var selectedTitle = params.colDef.headerName;

    let found = false;

    if (column === "Avail") {
      if (!found) {
        if (regionId === "*") {
          self.getAvailabilityData(
            selectedData,
            countryId,
            cityId,
            commonOD,
            getCabinValue,
            "Null",
            "Null"
          );
        } else if (countryId === "*") {
          self.getAvailabilityData(
            regionId,
            selectedData,
            cityId,
            commonOD,
            getCabinValue,
            "Null",
            "Null"
          );
        } else if (cityId === "*") {
          self.getAvailabilityData(
            regionId,
            countryId,
            cityId,
            commonOD,
            getCabinValue,
            "Null",
            "Null"
          );
        } else if (commonOD === "*") {
          self.getAvailabilityData(
            regionId,
            countryId,
            cityId,
            selectedData,
            getCabinValue,
            "Null",
            "Null"
          );
        } else {
          self.getAvailabilityData(
            regionId,
            countryId,
            cityId,
            commonOD,
            selectedData,
            "Null",
            "Null"
          );
        }
      }
    } else if (column === "Actual_A") {
      this.getInfareGraphData(params);
    } else if (column === "firstColumnName" && headName === "City") {
      this.getCityGraph(params);
    } else if (column === "MS_J9" && headName === "J9") {
      apiServices
        .get_MS_J9_Graph(
          params.data.Month,
          params.data.Year,
          regionId,
          countryId,
          params.data.firstColumnName
        )
        .then(function (result) {
          var columnName = result[0].columnName;
          var J9Data = result[0].J9Data;
          this.setState({
            tableModalVisible: true,
            modalCompartmentData: J9Data,
            modalCompartmentColumn: columnName,
            loading3: false,
          });
        });
    } else if (column === "Pax_J9" && headName === "J9") {
      this.get_Pax_J9_Graph(params);
    } else if (column === "firstColumnName" && selectedDataWQ !== "null") {
      if (!found) {
        this.storeValuesToLS(
          regionId,
          countryId,
          cityId,
          commonOD,
          getCabinValue,
          selectedDataWQ
        );

        if (selectedTitle !== "Compartment") {
          self.setState({ selectedData });
          bcData.push({ val: selectedDataWQ, title: selectedTitle });
        }

        if (regionId === "*") {
          self.getMarketShareMonthDrillDownData(
            selectedData,
            countryId,
            cityId,
            commonOD
          );
        } else if (countryId === "*") {
          self.getMarketShareMonthDrillDownData(
            regionId,
            selectedData,
            cityId,
            commonOD
          );
        } else if (cityId === "*") {
          self.getMarketShareMonthDrillDownData(
            regionId,
            countryId,
            selectedData,
            commonOD
          );
        } else if (commonOD === "*") {
          self.getMarketShareMonthDrillDownData(
            regionId,
            countryId,
            cityId,
            selectedData
          );
        }
      }
    }
  };

  getAvailabilityData(
    regionId,
    countryId,
    cityId,
    commonOD,
    getCabinValue,
    type,
    typeParam
  ) {
    const { gettingYear, gettingMonth } = this.state;
    this.showLoader();
    apiServices
      .getAvailabilityDetails(
        gettingYear,
        gettingMonth,
        regionId,
        countryId,
        cityId,
        commonOD,
        getCabinValue,
        type,
        typeParam
      )
      .then((result) => {
        this.hideLoader();
        if (result) {
          this.setState({
            availModalVisible: true,
            availModalColumn: result[0].columnName,
            availModalData: result[0].rowData,
          });
        }
      });
  }

  rectifyURLValues(regionId, countryId, cityId) {
    if (Array.isArray(regionId)) {
      this.selectedRegion = regionId.join(",");
    } else if (regionId.includes("','")) {
      this.selectedRegion = regionId.split("','").join(",");
      this.selectedRegion = this.selectedRegion.substring(
        1,
        this.selectedRegion.length - 1
      );
    } else {
      this.selectedRegion = regionId;
      this.selectedRegion = this.selectedRegion.substring(
        1,
        this.selectedRegion.length - 1
      );
    }
    if (Array.isArray(countryId)) {
      this.selectedCountry = countryId.join(",");
    } else if (regionId.includes("','")) {
      this.selectedCountry = countryId.split("','").join(",");
      this.selectedCountry = this.selectedCountry.substring(
        1,
        this.selectedCountry.length - 1
      );
    } else {
      this.selectedCountry = countryId;
      this.selectedCountry = this.selectedCountry.substring(
        1,
        this.selectedCountry.length - 1
      );
    }
    if (Array.isArray(cityId)) {
      this.selectedCity = cityId.join(",");
    } else if (regionId.includes("','")) {
      this.selectedCity = cityId.split("','").join(",");
      this.selectedCity = this.selectedCity.substring(
        1,
        this.selectedCity.length - 1
      );
    } else {
      this.selectedCity = cityId;
      this.selectedCity = this.selectedCity.substring(
        1,
        this.selectedCity.length - 1
      );
    }
  }

  storeValuesToLS(regionId, countryId, cityId, commonOD, getCabinValue, data) {
    let region = [];
    let country = [];
    let city = [];
    let cabin = [];

    this.rectifyURLValues(regionId, countryId, cityId);

    if (regionId === "*") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(data)}`
      );
      region.push(data);
      window.localStorage.setItem("RegionSelected", JSON.stringify(region));
    } else if (countryId === "*") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(
          this.selectedRegion
        )}&Country=${data}`
      );
      country.push(data);
      window.localStorage.setItem("CountrySelected", JSON.stringify(country));
    } else if (cityId === "*") {
      // this.props.history.push(
      //   `${this.pathName}?Region=${encodeURIComponent(
      //     this.selectedRegion
      //   )}&Country=${this.selectedCountry}&POS=${data}`
      // );
      // city.push(data);
      // window.localStorage.setItem("CitySelected", JSON.stringify(city));
    } else if (commonOD === "*") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(
          this.selectedRegion
        )}&Country=${this.selectedCountry}&POS=${
          this.selectedCity
        }&${encodeURIComponent("O&D")}=${data}`
      );
      window.localStorage.setItem("ODSelected", data);
    } else if (getCabinValue === "Null") {
      cabin.push(data);
      window.localStorage.setItem("CabinSelected", JSON.stringify(cabin));
    }
  }

  tabClick = (selectedType, outerTab) => {
    if (selectedType === "Route") {
      // this.props.history.push("/pos");
      let { period, gettingMonth, gettingYear } = this.state;

      // apiServices
      //   .getMarketShareRouteMonthTables(period, gettingMonth, gettingYear)
      //   .then(function (result) {
      //     self.setState({ loading: false, firstLoadList: false });
      //     if (result) {
      //       // var totalData = result[0].totalData;
      //       var columnName = result[0].columnName;
      //       var posMonthdata = result[0].rowData;
      //       self.monthURL = result[0].url;
      //       monthData = posMonthdata;
      //       self.setState({
      //         // posMonthDetails: self.getHighlightedMonth(
      //         //   posMonthdata,
      //         //   gettingMonth,
      //         //   gettingYear
      //         // ),
      //         posMonthDetails: posMonthdata,
      //         monthcolumns: columnName,
      //         // monthTotalData: totalData,
      //       });
      //     }
      //   });
    } else {
      var self = this;
      self.sendEvent(
        "2",
        `clicked on ${selectedType} tab`,
        "marketshare",
        "Marketshare Page"
      );
      let { regionId, countryId, cityId, commonOD } = this.state;
      self.setState({
        type: selectedType,
        drillDownData: [],
        drillDownTotalData: [],
        loading2: true,
      });

      if (outerTab) {
        this.setState({ outerTab });
      } else {
        this.setState({ outerTab: "" });
      }
      self.getDrillDownData(
        regionId,
        countryId,
        cityId,
        commonOD,
        selectedType
      );
    }
  };

  ODcellClick = (params) => {
    let {
      gettingMonth,
      regionId,
      countryId,
      cityId,
      commonOD,
      getCabinValue,
      gettingYear,
    } = this.state;

    var column = params.colDef.field;
    var selectedData = params.data.firstColumnName;

    if (column === "CY_AL") {
      window.localStorage.setItem("ODSelected", selectedData);
      this.props.history.push("/topMarkets");
    } else if (column === "Avail") {
      this.getAvailabilityData(
        regionId,
        countryId,
        cityId,
        commonOD,
        getCabinValue,
        "OD",
        selectedData
      );
    }
  };

  agentCellClick = (params) => {
    var column = params.colDef.field;
    var selectedData = params.data.firstColumnName;

    if (column === "firstColumnName") {
      window.localStorage.setItem("Agent", selectedData);
      window.location.href = "/agentAnalysis";
    }
  };

  compartmentCellClick = (params) => {
    let {
      gettingMonth,
      regionId,
      countryId,
      cityId,
      commonOD,
      getCabinValue,
      gettingYear,
    } = this.state;

    var column = params.colDef.field;
    var selectedData = params.data.firstColumnName;

    if (column === "Avail") {
      this.getAvailabilityData(
        regionId,
        countryId,
        cityId,
        commonOD,
        getCabinValue,
        "Compartment",
        selectedData
      );
    } else if (column === "FRCT/Act_A") {
      this.getInfareGraphData(params);
    }
  };

  homeHandleClick = (e) => {
    var self = this;
    self.sendEvent("2", "clicked on Network", "pos", "Pos Page");

    self.setState({
      loading: true,
      loading2: true,
      firstHome: false,
      posMonthDetails: [],
      monthTotalData: [],
      drillDownData: [],
      drillDownTotalData: [],
      toggle: "bc",
    });

    window.localStorage.setItem("RegionSelected", "Null");
    window.localStorage.setItem("CountrySelected", "Null");
    window.localStorage.setItem("CitySelected", "Null");
    window.localStorage.setItem("ODSelected", "Null");

    self.getMarketShareMonthDrillDownData("*", "*", "*", "*");

    bcData = [];
    var newURL = window.location.href.split("?")[0];
    window.history.pushState("object", document.title, newURL);
    // this.props.history.push('/pos')
  };

  listHandleClick = (data, title, selection) => {
    var self = this;
    self.sendEvent("2", "clicked on Drill down list", "pos", "Pos Page");
    let { regionId, countryId, cityId } = this.state;
    var selectedData = data;
    var getColName = decodeURIComponent(title);

    if (
      selectedData.charAt(0) !== "'" &&
      selectedData.charAt(selectedData.length - 1) !== "'"
    ) {
      selectedData = `'${data}'`;
    }
    if (data.includes(",") && getColName !== "Country") {
      selectedData = `'${data.split(",").join("','")}'`;
    }
    self.setState({
      selectedData,
      loading: true,
      loading2: true,
      posMonthDetails: [],
      monthTotalData: [],
      drillDownData: [],
      drillDownTotalData: [],
    });

    if (selection === "List") {
      var indexEnd = bcData.findIndex(function (d) {
        return d.title == title;
      });
      var removeArrayIndex = bcData.slice(0, indexEnd + 1);
      bcData = removeArrayIndex;
      this.changeURLOnListClick(regionId, countryId, cityId, data, getColName);
    } else if (selection === "browserBack") {
      this.onBackPressClearLS(getColName);
    }

    if (getColName === "Region") {
      self.getMarketShareMonthDrillDownData(selectedData, "*", "*", "*");
    } else if (getColName === "Country") {
      selectedData = String.removeQuotes(selectedData);
      selectedData = selectedData.split(",");

      self.getMarketShareMonthDrillDownData(regionId, selectedData, "*", "*");
    } else if (getColName === "POS") {
      self.getMarketShareMonthDrillDownData(
        regionId,
        countryId,
        selectedData,
        "*"
      );
    } else if (getColName === "O&D") {
      self.getMarketShareMonthDrillDownData(
        regionId,
        countryId,
        cityId,
        selectedData
      );
    }
  };

  changeURLOnListClick(regionId, countryId, cityId, selectedData, getColName) {
    this.rectifyURLValues(regionId, countryId, cityId);

    if (getColName === "Region") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(selectedData)}`
      );
      window.localStorage.setItem("CountrySelected", "Null");
      window.localStorage.setItem("CitySelected", "Null");
      window.localStorage.setItem("ODSelected", "Null");
    } else if (getColName === "Country") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(
          this.selectedRegion
        )}&Country=${selectedData}`
      );
      window.localStorage.setItem("CitySelected", "Null");
      window.localStorage.setItem("ODSelected", "Null");
    } else if (getColName === "POS") {
      // this.props.history.push(
      //   `${this.pathName}?Region=${encodeURIComponent(
      //     this.selectedRegion
      //   )}&Country=${this.selectedCountry}&POS=${selectedData}`
      // );
      window.localStorage.setItem("ODSelected", "Null");
    } else if (getColName === "O&D") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(
          this.selectedRegion
        )}&Country=${this.selectedCountry}&POS=${
          this.selectedCity
        }&${encodeURIComponent("O&D")}=${selectedData}`
      );
    }
  }

  onBackPressClearLS(getColName) {
    if (getColName === "Region") {
      window.localStorage.setItem("CountrySelected", "Null");
      window.localStorage.setItem("CitySelected", "Null");
      window.localStorage.setItem("ODSelected", "Null");
    } else if (getColName === "Country") {
      window.localStorage.setItem("CitySelected", "Null");
      window.localStorage.setItem("ODSelected", "Null");
    } else if (getColName === "POS") {
      window.localStorage.setItem("ODSelected", "Null");
    }
  }

  cabinSelectChange = (e) => {
    e.preventDefault();
    const getCabinValue = e.target.value;

    this.setState(
      {
        getCabinValue: getCabinValue,
        cabinSelectedDropDown: getCabinValue,
      },
      () => {
        window.localStorage.setItem(
          "CabinSelected",
          JSON.stringify(getCabinValue)
        );
      }
    );
  };

  onCabinClose() {
    var self = this;
    self.sendEvent("2", "clicked on Cabin drop down", "pos", "Pos Page");
    let { cabinSelectedDropDown } = this.state;

    if (cabinSelectedDropDown.length > 0) {
      this.getDataOnCabinChange();
    } else {
      this.setState({ getCabinValue: "Null" }, () =>
        this.getDataOnCabinChange()
      );
      window.localStorage.setItem("CabinSelected", "Null");
    }
  }

  getDataOnCabinChange() {
    var self = this;
    self.setState({
      loading: true,
      loading2: true,
      posMonthDetails: [],
      monthTotalData: [],
      drillDownData: [],
      drillDownTotalData: [],
    });
    let { regionId, countryId, cityId, commonOD } = this.state;
    self.getMonthDrillDownData(regionId, countryId, cityId, commonOD);
  }

  toggle = (e) => {
    let currency = e.target.value;
    let { regionId, countryId, cityId, commonOD } = this.state;
    this.sendEvent(
      "2",
      "clicked on Currency toggle",
      this.pathName,
      "Pos Page"
    );
    this.setState({ toggle: currency }, () =>
      this.getMonthDrillDownData(regionId, countryId, cityId, commonOD)
    );
  };

  monthChange = () => {
    const e = document.getElementById("monthRange");
    const gettingMonth = e.options[e.selectedIndex].value;
    const gettingYear = e.options[e.selectedIndex].id;
    let { regionId, countryId, cityId, commonOD } = this.state;
    this.sendEvent("2", "clicked on Month dropdown", this.pathName, "Pos Page");
    this.setState({ gettingMonth, gettingYear }, () =>
      this.getMonthDrillDownData(regionId, countryId, cityId, commonOD)
    );
    const range = {
      from: {
        year: parseInt(gettingYear),
        month: window.monthNameToNum(gettingMonth),
      },
      to: {
        year: parseInt(gettingYear),
        month: window.monthNameToNum(gettingMonth),
      },
    };
    window.localStorage.setItem("rangeValue", JSON.stringify(range));
  };

  redirection = (e) => {
    this.sendEvent("2", "clicked on POS/Route drop down", "pos", "Pos Page");
    let name = e.target.value;
    if (name === "Route") {
      this.props.history.push(`/route${Constant.getRouteFiltersSearchURL()}`);
      bcData = [];
    } else {
      this.props.history.push(this.url);
      bcData = [];
    }
  };

  addCellRender(columnDefs) {
    const a = columnDefs.map((c) => {
      if (c.field === "firstColumnName") {
        c["cellRenderer"] = this.alerts;
      }
      if (c.children) {
        c.children = this.addCellRender(c.children);
      }
      return c;
    });
    return a;
  }

  alerts = (params) => {
    const value = params.value;
    const header = params.colDef.headerName;
    var element = document.createElement("span");
    let isAlert = params.data.isAlert;
    isAlert = isAlert !== undefined ? isAlert.toString() : null;
    if (isAlert !== "0" && isAlert !== null) {
      // if (header !== 'Compartment' && header !== 'Agency' && header !== 'Ancillary') {
      var icon = document.createElement("i");
      icon.className = "fa fa-bell";
      icon.onclick = (e) => {
        e.stopPropagation();
        this.getAlertCardsData(header, value);
      };
      element.appendChild(icon);
    }
    element.appendChild(document.createTextNode(params.value));
    return element;
  };

  getAlertCardsData = (header, value) => {
    const {
      gettingMonth,
      gettingYear,
      regionId,
      countryId,
      cityId,
      commonOD,
      getCabinValue,
    } = this.state;
    const userData = JSON.parse(cookieStorage.getCookie("userDetails"));

    let region = Constant.addQuotesforMultiSelect(regionId);
    let country = Constant.getCountryCode(countryId);
    let city = Constant.addQuotesforMultiSelect(cityId);
    let cabin = Constant.addQuotesforMultiSelect(getCabinValue);
    let params = ``;

    if (header !== "Compartment") {
      params = `user_id=${userData.id}`;
    }

    if (header === "Region") {
      params = `${params}&regionId='${encodeURIComponent(value)}'`;
    }
    if (header === "Country") {
      params = `${params}&regionId=${region}&countryId=${Constant.getCountryCode(
        value
      )}`;
    }
    if (header === "POS") {
      params = `${params}&regionId=${region}&countryId=${country}&cityId='${encodeURIComponent(
        value
      )}'`;
    }
    if (header === "O&D") {
      params = `${params}&regionId=${region}&countryId=${country}&cityId=${city}&commonOD='${encodeURIComponent(
        value
      )}'`;
    }
    // if (header === 'Compartment') {
    //   params = `user_id'=${userData.id}&regionId=${(region)}&countryId=${(country)}&cityId=${(city)}&commonOD=${encodeURIComponent(commonOD)}`
    // }

    if (header !== "Compartment") {
      api
        .get(`getallalerts?${params}&infare=low`)
        .then((response) => {
          if (response) {
            if (response.data.response.length > 0) {
              let data = response.data.response;
              this.setState({
                alertData: data,
                alertVisible: true,
              });
            } else {
              this.setState({
                alertData: [],
                alertVisible: true,
              });
            }
          }
        })
        .catch((err) => {});
    }
  };
  getCityGraph = (params) => {
    console.log("getCityGraph");
  };

  get_Pax_J9_Graph = (params) => {
    console.log("getPaxJ9graph");
  };

  getInfareGraphData = (tableParams) => {
    const {
      gettingMonth,
      gettingYear,
      gettingDay,
      regionId,
      countryId,
      cityId,
      commonOD,
      getCabinValue,
      toggle,
      dayLevel,
    } = this.state;
    let region = Constant.addQuotesforMultiSelect(regionId);
    let country = Constant.getCountryCode(countryId);
    let city = Constant.addQuotesforMultiSelect(cityId);
    let cabin = Constant.addQuotesforMultiSelect(getCabinValue);

    let header =
      tableParams.columnApi.columnController.columnDefs[0].children[0]
        .headerName;
    let value = tableParams.data.firstColumnName;

    let params = ``;

    if (header !== "Compartment") {
      params = `gettingMonth=${window.monthNameToNum(
        gettingMonth
      )}&getYear=${gettingYear}&getCabinValue=${cabin}&currency_params=${toggle}`;
    }

    if (header === "Region") {
      params = `${params}&regionId='${encodeURIComponent(value)}'`;
    }
    if (header === "Country") {
      params = `${params}&regionId=${region}&countryId=${Constant.getCountryCode(
        value
      )}`;
    }
    if (header === "POS") {
      params = `${params}&regionId=${region}&countryId=${country}&cityId='${encodeURIComponent(
        value
      )}'`;
    }
    if (header === "O&D") {
      params = `${params}&regionId=${region}&countryId=${country}&cityId=${city}&commonOD='${encodeURIComponent(
        value
      )}'`;
    }
    if (header === "Compartment") {
      params = `currency_params=${toggle}&gettingMonth=${window.monthNameToNum(
        gettingMonth
      )}&getYear=${gettingYear}&getCabinValue='${value}'&regionId=${region}&countryId=${country}&cityId=${city}&commonOD=${encodeURIComponent(
        commonOD
      )}`;
    }

    if (dayLevel) {
      this.showLoader();
      apiServices
        .getInfareDayLevelData(
          gettingYear,
          gettingMonth,
          gettingDay,
          region,
          country,
          city,
          commonOD,
          cabin,
          toggle,
          header,
          value
        )
        .then((result) => {
          this.hideLoader();
          if (result) {
            this.setState({
              infareModalVisible: true,
              infareColumn: result[0].columnName,
              infareData: result[0].rowData,
              infareGraphHeading: `Infare Table of ${value}`,
            });
          }
        });
    } else {
      api
        .get(`infaregraph?${params}`)
        .then((response) => {
          if (response) {
            let graphData = response.data.response;
            let currency = "";
            // let currency = response.data.response[0].currencyData[0].Currency;
            if (graphData.length > 0) {
              this.setState({
                infareData: graphData,
                infareGraphHeading: `Infare Graph of ${value}`,
                infareCurrency: currency,
                infareModalVisible: true,
              });
            } else {
              this.setState({
                infareData: [],
                infareGraphHeading: `Infare Graph of ${value}`,
                infareCurrency: currency,
                infareModalVisible: true,
              });
            }
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong. Please try after some time",
            icon: "error",
            confirmButtonText: "Ok",
          }).then(() => {
            // window.location = '/'
            // cookieStorage.deleteCookie();
          });
        });
    }
  };

  serialize = (params) => {
    var str = [];
    for (var p in params)
      if (params.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
      }
    return str.join("&");
  };

  odsearchfilter = (e) => {
    const { regionId, countryId, cityId, commonOD, type, odsearchvalue } =
      this.state;
    var ODvalue = e.target.value;
    this.setState({ odsearchvalue: ODvalue });
    // var grid =  e.api.setQuickFilter(document.getElementById('filter-text-box').value);
    this.setState({ loading2: true });
    setTimeout(() => {
      this.getDrillDownData(regionId, countryId, cityId, commonOD, type);
    }, 4000);
  };

  monthLevelData() {
    let { regionId, countryId, cityId, commonOD } = this.state;
    window.location.pathname = "/pos";
    setTimeout(() => {
      this.setState({ dayLevel: false }, () =>
        this.getMonthDrillDownData(regionId, countryId, cityId, commonOD)
      );
    }, 1000);
  }

  dayLevelData() {
    let { regionId, countryId, cityId, commonOD } = this.state;
    window.location.pathname = "/posDay";
    setTimeout(() => {
      this.setState({ dayLevel: true }, () =>
        this.getMonthDrillDownData(regionId, countryId, cityId, commonOD)
      );
    }, 1000);
  }

  showLoader = () => {
    $("#loaderImage").addClass("loader-visible");
  };

  hideLoader = () => {
    $("#loaderImage").removeClass("loader-visible");
    $(".x_panel").addClass("opacity-fade");
    $(".top-buttons").addClass("opacity-fade");
  };

  renderTabs = () => {
    let { dayLevel, tabName, gettingYear, type, outerTab, ancillary } =
      this.state;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ul className="nav nav-tabs" role="tablist">
          <li role="presentation" onClick={() => this.tabClick("Route")}>
            <a
              href="#Section4"
              aria-controls="messages"
              role="tab"
              data-toggle="tab"
            >
              ROUTE
            </a>
          </li>
        </ul>
      </div>
    );
  };

  renderInfareGraphModal() {
    const { dayLevel } = this.state;
    return (
      <Modal
        show={this.state.infareModalVisible}
        onHide={() => this.setState({ infareModalVisible: false })}
        aria-labelledby="ModalHeader"
      >
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">{`${this.state.infareGraphHeading}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dayLevel ? (
            <DataTableComponent
              rowData={this.state.infareData}
              columnDefs={this.state.infareColumn}
            />
          ) : (
            <InfareMultiLineChart
              infareData={this.state.infareData}
              currency={this.state.infareCurrency}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  renderAvailModal() {
    return (
      <DataTableModal
        tableModalVisible={this.state.availModalVisible}
        rowData={this.state.availModalData}
        columns={this.state.availModalColumn}
        header={`Availability Details`}
        loading={this.state.loading3}
      />
    );
  }

  closeTableModal() {
    this.setState({ tableModalVisible: false });
  }

  render() {
    const {
      gettingMonth,
      gettingYear,
      cabinOption,
      cabinSelectedDropDown,
      cabinDisable,
      accessLevelDisable,
      outerTab,
      ancillary,
      dayLevel,
      monthRange,
      disableDropDown,
      rangeValuePicker,
    } = this.state;
    return (
      <div className="marketShare">
        <Loader />
        <TopMenuBar {...this.props} />
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 top">
            <div className="navdesign" style={{ marginTop: "0px" }}>
              <div
                className="col-md-5 col-sm-5 col-xs-5 toggle1"
                style={{ marginLeft: "10px" }}
              >
                <Link to="/marketShare">
                  <button
                    style={{
                      marginTop: "5px",
                      marginRight: "15px",
                      backgroundColor: "#1784c7",
                      color: "#fff",
                      border: "0",
                    }}
                  >
                    {" "}
                    Go Back{" "}
                  </button>
                </Link>
                <h5 style={{ marginTop: "10px", marginBottom: "10px", marginRight: "5px", color: "#1ee9d1" }}>Market Share Route</h5>
                {/* <DatePicker
                  rangeValue={rangeValuePicker}
                  handleRangeDissmis={this.handleRangeDissmis}
                /> */}
                {/* <nav>
                  <ol className="cd-breadcrumb">
                    <div
                      style={{
                        cursor: accessLevelDisable ? "not-allowed" : "pointer",
                      }}
                    >
                      <li
                        className={`${
                          accessLevelDisable ? "breadcrumb-disable" : ""
                        }`}
                        onClick={() => this.homeHandleClick()}
                      >
                        {" "}
                        {"Network"}{" "}
                      </li>
                    </div>
                    {this.state.firstLoadList
                      ? ""
                      : bcData.map((item) => (
                          <div style={{ cursor: "pointer" }}>
                            <li
                              className=""
                              onClick={(e) =>
                                this.listHandleClick(
                                  e.target.id,
                                  item.title,
                                  "List"
                                )
                              }
                              id={item.val}
                              title={`${item.title} : ${item.val}`}
                            >
                              {` > ${item.val}`}
                            </li>
                          </div>
                        ))}
                  </ol>
                </nav> */}
              </div>

              <div className="col-md-7 col-sm-7 col-xs-7 toggle2">
                {/* <label style={{ marginBottom: "0px", marginRight: "5px" }}>
                  Period
                </label>
                <select
                  className="form-control cabinselect pos-route-dropdown"
                  onChange={(e) => this.period(e)}
                >
                  <option value="monthly" selected={true}>
                    Month
                  </option>
                  <option value="yearly">Year</option>
                  <option value="quarterly">Quarter</option>
                </select> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div
              className="x_panel"
              style={{ marginTop: "10px", height: "calc(100vh - 130px)" }}
            >
              <div className="x_content">
                <DataTableComponent
                  rowData={this.state.posMonthDetails}
                  columnDefs={this.state.monthcolumns}
                  loading={this.state.loading}
                  onCellClicked={(cellData) =>
                    this.monthWiseCellClick(cellData)
                  }
                  rowClassRules={this.state.posMonthRowClassRule}
                  pos={true}
                  ensureIndexVisible={this.state.ensureIndexVisible}
                />
                <TotalRow
                  rowData={this.state.monthTotalData}
                  columnDefs={this.state.monthcolumns}
                  loading={this.state.loading}
                  // frameworkComponents={{
                  //   customHeaderGroupComponent: POSCustomHeaderGroup,
                  // }}
                  responsive={true}
                  reducingPadding={true}
                />
                <div
                  className="tab"
                  id="posTableTab"
                  role="tabpanel"
                  style={{ marginTop: "10px" }}
                >
                  {this.renderTabs()}

                  <div className="tab-content tabs">
                    <div
                      role="tabpanel"
                      className={`tab-pane fade in ${
                        ancillary ? "" : "active"
                      }`}
                      id="Section1"
                    >
                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        // onCellClicked={(cellData) =>
                        //   this.regionCellClick(cellData)
                        // }
                        loading={this.state.loading2}
                        pos={true}
                      />
                      <TotalRow
                        loading={this.state.loading2}
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        responsive={true}
                        reducingPadding={true}
                      />
                    </div>

                    <div
                      role="tabpanel"
                      className="tab-pane fade"
                      id="Section4"
                    >
                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        onCellClicked={(cellData) =>
                          this.agentCellClick(cellData)
                        }
                        loading={this.state.loading2}
                        pos={true}
                      />
                      <TotalRow
                        loading={this.state.loading2}
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        responsive={true}
                        reducingPadding={true}
                      />
                    </div>

                    <div
                      role="tabpanel"
                      className={`tab-pane fade in ${
                        ancillary ? "active" : ""
                      }`}
                      id="Section5"
                    >
                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        pos={true}
                      />
                      <TotalRow
                        loading={this.state.loading2}
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        responsive={true}
                        reducingPadding={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RBDModal
            tableModalVisible={this.state.tableModalVisible}
            datas={this.state.modalData}
            rowData={this.state.modalCompartmentData}
            columns={this.state.modalCompartmentColumn}
            header={`${this.state.dayLevel ? this.state.gettingDay : ""} ${
              this.state.gettingMonth
            } ${this.state.gettingYear}`}
            loading={this.state.loading3}
            closeTableModal={() => this.closeTableModal()}
          />
          <AlertModal
            alertVisible={this.state.alertVisible}
            alertData={this.state.alertData}
            closeAlertModal={() => this.setState({ alertVisible: false })}
          />

          <div>
            <DataTableModal
              tableModalVisible={this.state.tableModalVisible}
              datas={this.state.modalCompartmentData}
              columns={this.state.modalCompartmentColumn}
              header={`${this.state.gettingMonth}`}
              loading={this.state.loading3}
              closeTableModal={() => this.closeTableModal()}
            />
            {/* <ChartModelDetails
              chartVisible={this.state.chartVisible}
              datas={this.state.modelRegionDatas}
              columns={this.state.modelregioncolumn}
              closeChartModal={() => this.closeChartModal()}
            /> */}
          </div>

          {this.renderAvailModal()}

          {this.renderInfareGraphModal()}

          {/* {this.renderMarketShareGraphModal()} */}
        </div>
      </div>
    );
  }
}

const NewComponent = BrowserToProps(MarketShareRoute);

export default NewComponent;
