export const string = {
    NO_DATA_TO_SHOW: 'No Data to show',
    columnName: {
        MONTH: 'Month',
        BOOKINGS: 'Bookings',
        TKT: 'TKT(%)',
        PASSENGER_OD: 'Passenger-(OD)',
        PASSENGER_SECTOR: 'Passenger-(Sector)',
        FORECAST_ACT: 'FRCT/Act',
        AVERAGE_FARE_$: 'Average Fare($)',
        TICKETED_AVERAGE_FARE: 'Ticketed Average Fare(SR)',
        RBD: 'RBD',
        REVENUE_$: 'Revenue($)',
        OD: 'OD',
        MIDT_BOOKED_PASSENGER: 'MS Booked Passenger',
        MIDT_AIRLINES_BOOKED_PASSENGER: 'MS Airlines Booked Passenger',
        CY: 'CY',
        LY: 'LY',
        VLY: 'VLY(%)',
        YTD: 'YTD',
        O_AND_D_PASSENGER_FORECAST: 'OD Passenger',
        Forecast: 'Forecast',
        TGT: 'TGT',
        VTG: 'VTG(%)',
        REVENUE: 'Revenue',
        AL_MARKET_SHARE: 'AL Market Share(%)',
        TOP_COMPETITOR: 'TOP Competitor %',
        CY_ABS: 'CY(ABS)',
        AIRLINE: 'Airline',
        NAME: 'Name',
        CABIN: 'Cabin',
        CX: 'CX',
        AL: 'AL',
        MIDT_BOOKINGS: 'MS Bookings',
        MIDT_CY_BOOKINGS: 'MS CY Bookings',
        MIDT_LY_BOOKINGS: 'MS LY Bookings',
        MIDT_BOOKINGS_VLY: 'MS Bookings VLY(%)',
        CY_MS: 'CY MS',
        LY_MS: 'LY MS',
        MARKET_SHARE: 'Market Share(%)',
        AIRLINE_RANK: 'Airline Rank',
        AGENT_NAME: 'Agent Name',
        CY_PERCENTAGE: 'CY(%)',
        YOY_PERCENTAGE: 'YOY(%)',
        AL_PERCENTAGE: 'AL(%)',
        YOY_PTS: 'YOY(%)',
        CHANNEL: 'Channel',
        SA_SHARE: 'SA share',
        SA_PERCENTAGE: 'SA(%)',
        PASSENGER: 'Passenger',
        FORECAST: 'Forecast',
        AGENTS: 'Agents',
        RASK_DOLLAR: 'RASK($)',
        ASK: 'ASK',
        YIELD: 'Yield',
        LOAD_FACTOR: 'Load Factor',
        HUB: 'Hub',
        AGENCY: 'Agency',
        ANCIALLARY_ITEMS: 'Ancillary',
        REGION: 'Region',
        COUNTRY: 'Country',
        AVAIL: 'Avail',
        LOWEST_RBD: 'Lowest RBD',
        DOH_LHR: 'DOH-LHR',
        LHR_DOH: 'LHR-DOH',
        POS: 'POS',
        COMMON_OD: 'Common OD',
        COMPARTMENT: 'Compartment',
        FLIGHT: 'Flight',
        TARGET: 'Target',
        ACTUAL: 'Actual',
        ERROR_PERCENT: 'Error(%)',
        ACCURACY: 'Accuracy(%)',
        RO: 'RO',
        SALES: 'Sales',
        FIT: 'FIT',
        GIT: 'GIT',
        SHARE: 'Share',
        FARE_SHARE: 'Fare Share',
        TOP_AL_SHARE: 'Top AL/Share',
        BUDGET: 'BGT',
        ROUTE: 'Route',
        REVENUE_RECEIVED: 'Revenue Received',
        REVENUE_GIVEN: 'Revenue Given',
        YQ_RETAINED: 'YQ Retained',
        PAX_SECTOR: 'Pax (Sector)',
        COMMISION_RECEIVED: 'Commision Received',
        COMMISION_GIVEN: 'Commision Given',
        SQ_AVG_FARE: 'SQ Avg Fare',
        PARTNERSHIP_AVG_FARE: 'Partnership Avg Fare'
    }

}
