import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import DataTableComponent from '../Component/DataTableComponent'
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "./modal.scss";

class DatatableModelDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
    }

    handleChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded ? panel : false });
    };

    closeModal = () => {
        this.props.closeTableModal();
        this.setState({ expanded: false })
    }


    render() {
        const modalData = this.props.datas ? this.props.datas : [];
        return (
            <div>
                <Modal
                    show={this.props.tableModalVisible}
                    onHide={() => this.closeModal()}
                    aria-labelledby="ModalHeader"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader'>{`Bookings RBD details for ${this.props.header}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'root'}>
                            {modalData.map((data, i) => {
                                return (
                                    <ExpansionPanel expanded={this.state.expanded === data[0].Cabin} onChange={this.handleChange(data[0].Cabin)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className={'heading'}>{data[0].Cabin}</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <DataTableComponent
                                                rowData={this.props.rowData.filter((e, i) => e.Cabin === data[0].Cabin)}
                                                columnDefs={this.props.columns}
                                                autoHeight={'autoHeight'}
                                                // modal={true}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )
                            })}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}

export default DatatableModelDetails;




