import React, { Component } from "react";
import APIServices from "../../API/apiservices";
import eventApi from "../../API/eventApi";
// import DataTableModal from "../../Component/DataTableModal";
import api from "../../API/api";
import AlertModal from "../../Component/AlertModal";
import InfareMultiLineChart from "../../Component/InfareMultiLineChart";
import RBDModal from "../../Component/RBDModal";
import DataTableModal from "../../Component/DataTableModal";
import Loader from "../../Component/Loader";
import cookieStorage from "../../Constants/cookie-storage";
import String from "../../Constants/validator";
import URLSearchParams from "../../Constants/validator";
import Access from "../../Constants/accessValidation";
import DownloadCSV from "../../Component/DownloadCSV";
import DataTableComponent from "../../Component/DataTableComponent";
import TotalRow from "../../Component/TotalRow";
import { string } from "../../Constants/string";
import color from "../../Constants/color";
import Constant from "../../Constants/validator";
import $ from "jquery";
import "../../App";
import "./index.scss";
import TopMenuBar from "../../Component/TopMenuBar";
import MarketShareCustomHeaderGroup from "./MarketShareCustomHeaderGroup";

import Modal from "react-bootstrap-modal";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import BrowserToProps from "react-browser-to-props";
import Swal from "sweetalert2";
import config from "../../Constants/config";
import { result } from "lodash";
import DatePicker from "../../Component/DatePicker";
import { Link } from "react-router-dom";

import * as d3 from "d3";
import MultiLineChartLegends from "../../Component/MultiLineChartLegends";
// import POSDetail from "../PosDetails";
import Chart from "react-apexcharts";
import ChartD3 from "../../Component/DrawBarChart";
import Spinners from "../../spinneranimation";

import leadingMarketShare from "../../APIResponses/leadingMarketShare.json"
import leadingMarketShareYear from "../../APIResponses/leadingMarketShareYear.json"
import getAirline from "../../APIResponses/getAirline.json"
import marketCityWiseDetails from "../../APIResponses/marketCityWiseDetails.json"

const apiServices = new APIServices();
const API_URL = config.API_URL;

const currentYear = new Date().getFullYear();
let monthData = [];
let bcData = [];
const rangeValue = {
  from: { year: new Date().getFullYear(), month: 1 },
  to: { year: new Date().getFullYear(), month: new Date().getMonth() + 1 },
};

export const CustomDropdown = (props) => (
  <div className="form-group">
    <strong>{props.AirlineName}</strong>
    <select
      className="form-control cabinselect pos-route-dropdown"
      name="{props.AirlineName}"
      onChange={props.onChange}
    >
      <option value="Null"> All Airlines {props.name}</option>
      {props.options.map((item, index) => (
        <option key={index} value={item.id}>
          {item.AirlineName}
        </option>
      ))}
    </select>
  </div>
);

class MarketShare extends Component {
  constructor(props) {
    super(props);
    this.selectedRegion = null;
    this.selectedCountry = null;
    this.selectedCity = null;
    this.monthURL = "";
    this.dayURL = "";
    this.drilldownURL = "";
    this.state = {
      posMonthDetails: [],
      monthTotalData: [],
      drillDownTotalData: [],
      monthcolumns: [],
      drillDownColumn: [],
      drillDownData: [],
      modaldrillDownColumn: [],
      modaldrillDownData: [],
      modalData: [],
      modalCompartmentData: [],
      modalCompartmentColumn: [],
      availModalData: [],
      availModalColumn: [],
      availTotalData: [],
      availModalVisible: false,
      tableDatas: true,
      gettingMonth: null,
      gettingYear: null,
      gettingDay: 1,
      gettingRegion: "Null",
      monthTableTitle: "NETWORK",
      tabLevel: "Null",
      cabinOption: [],
      monthRange: [],
      alertData: [],
      getCabinValue: [],
      getAirline: "Null",
      airlineCollection: [],
      period: "monthly",
      getGraphPeriod: "monthly",
      cityForGraph: "*",
      startDate: this.formatDate(
        new Date(rangeValue.from.year, rangeValue.from.month - 1, 1)
      ),
      endDate: this.formatDate(
        new Date(rangeValue.to.year, rangeValue.to.month, 0)
      ),
      cabinSelectedDropDown: [],
      cabinDisable: false,
      toggle: "bc",
      alertVisible: false,
      tableModalVisible: false,
      tabName: "Region",
      regionId: "*",
      countryId: "*",
      cityId: "*",
      commonOD: "*",
      type: "Null",
      baseAccess: "",
      selectedData: "Null",
      infareData: [],
      infareColumn: [],
      infareModalVisible: false,
      marketShareModalVisible: false,
      infareGraphHeading: "",
      loading: false,
      loading2: false,
      loading3: false,
      firstLoadList: false,
      show_LL2Y_Rows: false,
      show_LLY_Rows: false,
      showLastYearRows: false,
      showNextYearRows: false,
      accessLevelDisable: false,
      posMonthRowClassRule: {
        "highlight-row": "data.highlightMe",
      },
      firstHome: true,
      infareCurrency: "",
      outerTab: false,
      ancillary: false,
      ensureIndexVisible: null,
      dayLevel: false,
      disableDropDown: false,
      odsearchvalue: "",
      rangeValuePicker: rangeValue,
      cityFrequencyPeriod: "monthly",
      cityFrequencyModal: false,
      cityFrequencyGraph: "Table",
      cityFrequencyModalData: [],
      cityFrequencyModalColumn: [],
      cityFrequencyModalSwitchGraph: false,
      cityFrequencyGraphColors: [
        "#03A9F4",
        "#4CAF50",
        "#FFC107",
        "#FF5722",
        "#607D8B",
        "#14558f",
        "#43956f",
        "#f6c51b",
        "#535353",
        "#9C27B0",
        "#1A237E",
        "#1976D2",
        "#26C6DA",
        "#9CCC65",
      ],

      cityWisePeriod: "monthly",
      cityWiseModal: false,
      cityWiseGraph: "Table",
      cityWiseModalData: [],
      cityWiseModalColumn: [],
      cityWiseModalSwitchGraph: false,
      cityWiseGraphColors: [
        "#03A9F4",
        "#4CAF50",
        "#FFC107",
        "#FF5722",
        "#607D8B",
        "#14558f",
        "#43956f",
        "#f6c51b",
        "#535353",
        "#9C27B0",
        "#1A237E",
        "#1976D2",
        "#26C6DA",
        "#9CCC65",
      ],

      ageGroupData: [],
      colors: [
        "#0571b0",
        "#92c5de",
        "#46b6ed",
        "#4CAF50",
        "#d5d5d5",
        "#92c5de",
        "#0571b0",
      ],

      isLoading: false,
      series: [
        {
          data: [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 1,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
    };
    this.sendEvent(
      "1",
      "viewed MarketShare Page",
      "marketShare",
      "MarketShare"
    );
    this.pathName = window.location.pathname;
  }

  sendEvent = (id, description, path, page_name) => {
    var eventData = {
      event_id: `${id}`,
      description: `User ${description}`,
      where_path: `/${path}`,
      page_name: `${page_name}`,
    };
    eventApi.sendEvent(eventData);
  };
  componentDidMount() {
    fetch(`${API_URL}/getAirline`)
      .then((response) => getAirline)
      .then((res) => this.setState({ airlineCollection: getAirline }));
  }
  onChange = (event) => {
    let { regionId, countryId, cityId, commonOD, getAirline } = this.state;
    this.setState({ getAirline: event.target.value }, () =>
      this.getMarketShareMonthDrillDownData(
        regionId,
        countryId,
        cityId,
        commonOD
      )
    );
    // window.localStorage.setItem("AirlineSelected", JSON.stringify(getAirline));
  };

  componentWillMount() {
    var self = this;
    const ancillary = URLSearchParams.getParameterByName(
      "ancillary",
      window.location.href
    );
    const isDaylevel = self.pathName === "/posDay" ? true : false;
    const cabinData = JSON.parse(window.localStorage.getItem("classData"));
    const monthRange = JSON.parse(
      window.localStorage.getItem("monthRangeDropdown")
    );
    this.setState({
      ancillary: ancillary ? ancillary : false,
      dayLevel: isDaylevel,
      cabinOption: cabinData,
      monthRange: monthRange,
      disableDropDown: !Access.accessValidation("Dashboard", "routeDashboard")
        ? true
        : false,
    });
    self.getFiltersValue();
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {
      const obj = this.props.browserToProps.queryParams;
      let data = Object.values(obj);
      let title = Object.keys(obj);
      const lastIndex = title.length - 1;
      if (data[0] !== "undefined") {
        this.pushURLToBcData(obj, title, data, lastIndex);
        this.setState({ firstHome: true });
      } else {
        if (this.state.firstHome) {
          this.homeHandleClick();
        }
      }
    };
  }

  pushURLToBcData(obj, title, data, lastIndex) {
    const self = this;
    let region = [];
    let country = [];
    let city = [];

    if (
      obj.hasOwnProperty("Region") &&
      !bcData.some(function (o) {
        return o["title"] === "Region";
      })
    ) {
      let data = obj["Region"];
      let bcContent = obj["Region"];
      let multiSelectLS;
      let regionId;

      if (data.includes(",")) {
        data = `'${data.split(",").join("','")}'`;
      } else if (
        data.charAt(0) !== "'" &&
        data.charAt(data.length - 1) !== "'"
      ) {
        data = `'${data}'`;
      }

      if (
        bcContent.charAt(0) === "'" &&
        bcContent.charAt(bcContent.length - 1) === "'"
      ) {
        regionId = bcContent.substring(1, bcContent.length - 1);
      } else if (bcContent.includes(",")) {
        multiSelectLS = bcContent.split(",");
        regionId = bcContent;
      }
      // else if(bcContent.includes("','")){
      //   multiSelectLS = bcContent.split("','");
      //   regionId = bcContent;
      // }
      else {
        regionId = bcContent;
      }
      bcData.push({ val: regionId, title: "Region" });
      self.setState({ regionId: data });
      let regionLS = bcContent.includes(",")
        ? multiSelectLS
        : region.concat([regionId]);
      window.localStorage.setItem("RegionSelected", JSON.stringify(regionLS));
    }
    if (
      obj.hasOwnProperty("Country") &&
      !bcData.some(function (o) {
        return o["title"] === "Country";
      })
    ) {
      let data = obj["Country"];
      let bcContent = decodeURIComponent(obj["Country"]);
      let multiSelectLS;
      let countryId;

      if (data.includes(`','`)) {
        data = `'${data}'`;
      } else if (data.includes(",")) {
        data = `'${data.split(",").join("','")}'`;
      } else if (
        data.charAt(0) !== "'" &&
        data.charAt(data.length - 1) !== "'"
      ) {
        data = `'${data}'`;
      }

      self.setState({ countryId: data });

      if (
        bcContent.charAt(0) === "'" &&
        bcContent.charAt(bcContent.length - 1) === "'"
      ) {
        countryId = bcContent.substring(1, bcContent.length - 1);
      } else if (bcContent.includes(`','`)) {
        multiSelectLS = bcContent.split(`','`);
        countryId = bcContent.split(`','`).join(",");
      } else if (bcContent.includes(",")) {
        multiSelectLS = bcContent.split(",");
        countryId = bcContent;
      } else {
        countryId = bcContent;
      }
      bcData.push({ val: countryId, title: "Country" });
      let countryLS = bcContent.includes(",")
        ? multiSelectLS
        : country.concat([countryId]);
      window.localStorage.setItem(
        "RouteCountrySelected",
        JSON.stringify(countryLS)
      );
    }
    if (
      obj.hasOwnProperty("POS") &&
      !bcData.some(function (o) {
        return o["title"] === "POS";
      })
    ) {
      let data = obj["POS"];
      let bcContent = obj["POS"];
      let multiSelectLS;
      let cityId;

      if (data.includes(",")) {
        data = `'${data.split(",").join("','")}'`;
      } else if (
        data.charAt(0) !== "'" &&
        data.charAt(data.length - 1) !== "'"
      ) {
        data = `'${data}'`;
      }
      if (
        bcContent.charAt(0) === "'" &&
        bcContent.charAt(bcContent.length - 1) === "'"
      ) {
        cityId = bcContent.substring(1, bcContent.length - 1);
      } else if (bcContent.includes(",")) {
        multiSelectLS = bcContent.split(",");
        cityId = bcContent;
      } else {
        cityId = bcContent;
      }

      bcData.push({ val: cityId, title: "POS" });
      self.setState({ cityId: data });
      let cityLS = bcContent.includes(",")
        ? multiSelectLS
        : city.concat([cityId]);
      // window.localStorage.setItem("CitySelected", JSON.stringify(cityLS));
    }
    if (
      obj.hasOwnProperty("O%26D") &&
      !bcData.some(function (o) {
        return o["title"] === "O&D";
      })
    ) {
      bcData.push({ val: obj["O%26D"], title: "O&D" });

      self.setState({ commonOD: obj["O%26D"] });
      window.localStorage.setItem("ODSelected", obj["O%26D"]);
    }

    if (bcData.length > 0) {
      var removeArrayIndex = bcData.slice(0, lastIndex + 1);
      bcData = removeArrayIndex;
    }
    
    this.listHandleClick(data[lastIndex], title[lastIndex], "browserBack");
  }

  period = (e) => {
    let target = e.target.value;
    let { regionId, countryId, cityId } = this.state;
    // let rangeValue = JSON.parse(window.localStorage.getItem("rangeValue"));
    // let monthNum = window.monthNumToName(rangeValue.from.month)
    let monthVal = target === 'quarterly' ? 'Jan - Mar' : "January"
    this.setState({ period: target, gettingMonth: monthVal }, () =>
      this.getMarketShareMonthDrillDownData(regionId, countryId, cityId)
    );
  };

  handleRangeDissmis = (value) => {
    let { regionId, countryId, cityId } = this.state;
    let startDate = this.formatDate(
      new Date(value.from.year, value.from.month - 1, 1)
    );
    let endDate = this.formatDate(new Date(value.to.year, value.to.month, 0));
    this.setState(
      {
        rangeValuePicker: value,
        startDate: startDate,
        endDate: endDate,
      },
      () => this.getMarketShareMonthDrillDownData(regionId, countryId, cityId)
    );
    window.localStorage.setItem("rangeValue", JSON.stringify(value));
  };

  formatDate = (d) => {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  getFiltersValue = () => {
    bcData = [];
    let RegionSelected = window.localStorage.getItem("RegionSelected");
    let CountrySelected = window.localStorage.getItem("CountrySelected");
    let CitySelected = window.localStorage.getItem("CitySelected");
    let rangeValue = JSON.parse(window.localStorage.getItem("rangeValue"));
    let getCabinValue = window.localStorage.getItem("CabinSelected");
    let ODSelected = window.localStorage.getItem("ODSelected");
    let startDate = "";
    let endDate = "";

    if (rangeValue !== null) {
      startDate = this.formatDate(
        new Date(rangeValue.from.year, rangeValue.from.month - 1, 1)
      );
      endDate = this.formatDate(
        new Date(rangeValue.to.year, rangeValue.to.month, 0)
      );
      this.setState({ rangeValuePicker: rangeValue });
    }
    startDate = startDate === "" ? this.state.startDate : startDate;
    endDate = endDate === "" ? this.state.endDate : endDate;

    // let getAirline = window.localStorage.getItem("getAirline");
    // let period = window.localStorage.getItem("period");

    let cabinSelectedDropDown =
      getCabinValue === null || getCabinValue === "Null"
        ? []
        : JSON.parse(getCabinValue);
    getCabinValue =
      cabinSelectedDropDown.length > 0 ? cabinSelectedDropDown : "Null";

    CitySelected =
      CitySelected === null || CitySelected === "Null" || CitySelected === ""
        ? "*"
        : JSON.parse(CitySelected);

    // getAirline =
    //   getAirline === null || getAirline === "Null" || getAirline === ""
    //     ? "Null"
    //     : JSON.parse(getAirline);

    // period =
    //   period === null || period === "Null" || period === "monthly"
    //     ? "monthly"
    //     : JSON.parse(period);

    this.setState(
      {
        regionId:
          RegionSelected === null ||
          RegionSelected === "Null" ||
          RegionSelected === ""
            ? "*"
            : JSON.parse(RegionSelected),
        // getAirline:
        //   getAirline === null || getAirline === "Null" || getAirline === ""
        //     ? "Null"
        //     : JSON.parse(getAirline),

        // period:
        //   period === null || period === "Null" || period === "monthly"
        //     ? "monthly"
        //     : JSON.parse(period),
        countryId:
          CountrySelected === null ||
          CountrySelected === "Null" ||
          CountrySelected === ""
            ? "*"
            : JSON.parse(CountrySelected),
        cityId: CitySelected,
        commonOD:
          ODSelected === null ||
          ODSelected === "Null" ||
          ODSelected === "" ||
          CitySelected === "*"
            ? "*"
            : `'${ODSelected}'`,
        gettingMonth: window.monthNumToName(rangeValue.from.month),
        gettingYear: rangeValue.from.year,
        getCabinValue: getCabinValue,
        cabinSelectedDropDown: cabinSelectedDropDown,
        startDate: startDate,
        endDate: endDate,
        // getAirline: getAirline,
        // period: period,
      },
      () => this.getInitialData()
    );
  };

  getInitialData = () => {
    var self = this;
    let { regionId, countryId, cityId, commonOD } = this.state;
    self.setState({
      loading: true,
      loading2: true,
      firstLoadList: true,
      posMonthDetails: [],
      monthTotalData: [],
      drillDownData: [],
      drillDownTotalData: [],
    });

    self.getInitialListData(regionId, countryId, cityId, commonOD);

    self.getMarketShareMonthDrillDownData(
      regionId,
      countryId,
      cityId,
      commonOD,
      "initial"
    );
  };

  getInitialListData = (regionId, countryId, cityId, OD) => {
    const self = this;
    let commonOD = OD.substring(1, OD.length - 1);

    /*if (Constant.getAccessStatus().hasAccess) {
          this.setState({ accessLevelDisable: true })
        }*/

    if (regionId !== "*") {
      bcData.push({ val: regionId, title: "Region" });
      self.setState({ selectedData: regionId });
    }
    if (countryId !== "*") {
      bcData.push({ val: countryId, title: "Country" });
      self.setState({ selectedData: countryId });
    }
    if (cityId !== "*") {
      bcData.push({ val: cityId, title: "POS" });
      self.setState({ selectedData: cityId });
    }
    if (cityId !== "*") {
      if (commonOD !== "*") {
        bcData.push({ val: commonOD, title: "O&D" });
        self.setState({ selectedData: OD });
      }
    }
  };

  getMarketShareMonthDrillDownData = (
    regionId,
    countryId,
    cityId,
    commonOD,
    initial
  ) => {
    var self = this;
    let {
      gettingMonth,
      // getCabinValue,
      type,
      gettingYear,
      toggle,
      gettingDay,
      dayLevel,
      ancillary,
      odsearchvalue,
      getAirline,
      period,
      startDate,
      endDate,
    } = this.state;

    
    self.setState({
      loading: true,
      loading2: true,
      posMonthDetails: [],
      monthTotalData: [],
      drillDownData: [],
      drillDownTotalData: [],
    });

    if (dayLevel) {
      apiServices
        .getPOSDayTables(
          toggle,
          gettingYear,
          gettingMonth,
          regionId,
          countryId,
          cityId,
          commonOD
          // getCabinValue
        )
        .then(function (result) {
          self.setState({ loading: false, firstLoadList: false });
          if (result) {
            var totalData = result[0].totalData;
            var columnName = result[0].columnName;
            var posDaydata = result[0].rowData;
            self.dayURL = result[0].url;
            self.setState({
              posMonthDetails: self.getHighlightedDay(posDaydata, gettingDay),
              monthcolumns: columnName,
              monthTotalData: totalData,
            });
          }
        });
    } else {
      self.setState({ loading: false, firstLoadList: false });
      apiServices
        .getMarketShareMonthTables(
          toggle,
          regionId,
          countryId,
          cityId,

          getAirline,
          period,
          startDate,
          endDate
        )
        .then(function (result) {
          if (result) {
            const totalData = result[0].totalData;
            const columnName = result[0].columnName;
            const posMonthdata = result[0].rowData;
            self.monthURL = result[0].url;
            monthData = posMonthdata;
            self.setState({
              posMonthDetails: self.getHighlightedMonth(
                posMonthdata,
                gettingMonth,
                gettingYear
              ),
              // posMonthDetails: posMonthdata,
              monthcolumns: columnName,
              monthTotalData: totalData,
            });
          }
        });
    }

    let selectedType = "";
    if (ancillary && initial) {
      selectedType = "Ancillary";
      self.setState({ type: "Ancillary" });
    } else {
      selectedType = type;
    }

    apiServices
      .getMarketShareDrillDownData(
        regionId,
        countryId,
        cityId,

        getAirline,
        period,
        gettingYear,

        gettingMonth,
        selectedType
      )

      .then((result) => {
        self.setState({ loading2: false });
        if (result) {
          self.drilldownURL = result[0].url;
          let countryId = result[0].currentAccess.countryId;
          self.setState({
            drillDownTotalData: result[0].totalData,
            drillDownData: result[0].rowData,
            drillDownColumn: self.addCellRender(result[0].columnName),
            tabName:
              selectedType === "Null"
                ? result[0].tabName
                : result[0].firstTabName,
            regionId: result[0].currentAccess.regionId,
            countryId:
              countryId === "*"
                ? countryId
                : Constant.getFullCountryName(countryId),
            cityId: result[0].currentAccess.cityId,
            getAirline: getAirline,
            period: period,
          });
        }
      });
  };

  getDrillDownData = (regionId, countryId, cityId, commonOD, type) => {
    var self = this;
    let {
      // getCabinValue,
      // toggle,
      // dayLevel,
      // gettingDay,
      // odsearchvalue,
      getAirline,
      period,
      gettingYear,
      gettingMonth,
      // startDate,
      // endDate,
    } = this.state;

    apiServices
      .getMarketShareDrillDownData(
        regionId,
        countryId,
        cityId,
        getAirline,
        period,
        gettingYear,
        gettingMonth,
        type
      )
      .then((result) => {
        self.setState({ loading2: false });
        if (result) {
          self.drilldownURL = result[0].url;
          let countryId = result[0].currentAccess.countryId;
          self.setState({
            drillDownTotalData: result[0].totalData,
            drillDownData: result[0].rowData,
            drillDownColumn: self.addCellRender(result[0].columnName),
            tabName:
              type === "Null" ? result[0].tabName : result[0].firstTabName,
            regionId: result[0].currentAccess.regionId,
            countryId:
              countryId === "*"
                ? countryId
                : Constant.getFullCountryName(countryId),
            cityId: result[0].currentAccess.cityId,
            getAirline: getAirline,
            period: period,
            gettingYear,
            gettingMonth,

            // commonOD: result[0].currentAccess.commonOD,
          });
        }
      });
  };

  getHighlightedMonth(posMonthdata, month, year) {
    const { period } = this.state;
    let count = 0;
    let data = [];
    if (period === 'monthly'){
      data = posMonthdata.filter((data, index) => {
        var monthName = data.Month;
        if (year !== currentYear - 1) {
          if (data.Year === currentYear - 1) {
            count = count + 1;
          }
        }
        if (Constant.selectedMonthWithYear(month, year) === monthName) {
          data.highlightMe = true;
          this.setState({ ensureIndexVisible: index - count });
        }
        
        if (year === currentYear) {
          if (data.Month === `Total ${currentYear - 3}`) {
            data.Month = `► Total ${currentYear - 3}`;
          }
          if (data.Month === `Total ${currentYear - 2}`) {
            data.Month = `► Total ${currentYear - 2}`;
          }
          if (data.Month === `Total ${currentYear - 1}`) {
            data.Month = `► Total ${currentYear - 1}`;
          }
          if (data.Month === `Total ${currentYear + 1}`) {
            data.Month = `► Total ${currentYear + 1}`;
          }
          if (
            data.Year === currentYear ||
            data.Month === `► Total ${currentYear - 3}` ||
            data.Month === `► Total ${currentYear - 2}` ||
            data.Month === `► Total ${currentYear - 1}` ||
            data.Month === `► Total ${currentYear + 1}`
          ) {
            return data;
          }
        } else if (year === currentYear + 1) {
          if (data.Month === `Total ${currentYear - 3}`) {
            data.Month = `► Total ${currentYear - 3}`;
          }
          if (data.Month === `Total ${currentYear - 2}`) {
            data.Month = `► Total ${currentYear - 2}`;
          }
          if (data.Month === `Total ${currentYear - 1}`) {
            data.Month = `► Total ${currentYear - 1}`;
          }
          if (data.Month === `Total ${currentYear + 1}`) {
            data.Month = `▼ Total ${currentYear + 1}`;
          }
          if (
            data.Year === currentYear + 1 ||
            data.Year === currentYear ||
            data.Month === `► Total ${currentYear - 3}` ||
            data.Month === `► Total ${currentYear - 2}` ||
            data.Month === `► Total ${currentYear - 1}` ||
            data.Month === `▼ Total ${currentYear + 1}`
          ) {
            return data;
          }
          this.setState({
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
            showNextYearRows: true,
            showLastYearRows: false,
          });
        } else if (year === currentYear - 1) {
          if (data.Month === `Total ${currentYear - 3}`) {
            data.Month = `► Total ${currentYear - 3}`;
          }
          if (data.Month === `Total ${currentYear - 2}`) {
            data.Month = `► Total ${currentYear - 2}`;
          }
          if (data.Month === `Total ${currentYear - 1}`) {
            data.Month = `▼ Total ${currentYear - 1}`;
          }
          if (data.Month === `Total ${currentYear + 1}`) {
            data.Month = `► Total ${currentYear + 1}`;
          }
          if (
            data.Year === currentYear - 1 ||
            data.Year === currentYear ||
            data.Month === `► Total ${currentYear - 3}` ||
            data.Month === `► Total ${currentYear - 2}` ||
            data.Month === `▼ Total ${currentYear - 1}` ||
            data.Month === `► Total ${currentYear + 1}`
          ) {
            return data;
          }
          this.setState({
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
            showLastYearRows: true,
            showNextYearRows: false,
          });
        } else if (year === currentYear - 2) {
          if (data.Month === `Total ${currentYear - 3}`) {
            data.Month = `► Total ${currentYear - 3}`;
          }
          if (data.Month === `Total ${currentYear - 2}`) {
            data.Month = `▼ Total ${currentYear - 2}`;
          }
          if (data.Month === `Total ${currentYear - 1}`) {
            data.Month = `► Total ${currentYear - 1}`;
          }
          if (data.Month === `Total ${currentYear + 1}`) {
            data.Month = `► Total ${currentYear + 1}`;
          }
          if (
            data.Year === currentYear - 2 ||
            data.Year === currentYear ||
            data.Month === `► Total ${currentYear - 3}` ||
            data.Month === `▼ Total ${currentYear - 2}` ||
            data.Month === `► Total ${currentYear - 1}` ||
            data.Month === `► Total ${currentYear + 1}`
          ) {
            return data;
          }
          this.setState({
            show_LL2Y_Rows: false,
            show_LLY_Rows: true,
            showLastYearRows: false,
            showNextYearRows: false,
          });
        } else if (year === currentYear - 3) {
          if (data.Month === `Total ${currentYear - 3}`) {
            data.Month = `▼ Total ${currentYear - 3}`;
          }
          if (data.Month === `Total ${currentYear - 2}`) {
            data.Month = `► Total ${currentYear - 2}`;
          }
          if (data.Month === `Total ${currentYear - 1}`) {
            data.Month = `► Total ${currentYear - 1}`;
          }
          if (data.Month === `Total ${currentYear + 1}`) {
            data.Month = `► Total ${currentYear + 1}`;
          }
          if (
            data.Year === currentYear - 3 ||
            data.Year === currentYear ||
            data.Month === `▼ Total ${currentYear - 3}` ||
            data.Month === `► Total ${currentYear - 2}` ||
            data.Month === `► Total ${currentYear - 1}` ||
            data.Month === `► Total ${currentYear + 1}`
          ) {
            return data;
          }
          this.setState({
            show_LL2Y_Rows: true,
            show_LLY_Rows: false,
            showLastYearRows: false,
            showNextYearRows: false,
          });
        }
      });

    } else if(period === 'yearly'){
      data = posMonthdata.filter((data, index) => {
        if (year === data.Year) {
          data.highlightMe = true;
          this.setState({ ensureIndexVisible: index - 1 });
        }
        if (
          data.Year === currentYear ||
          (data.Year === (currentYear - 3) && data.Month !== `Total ${currentYear - 3}`) ||
          (data.Year === (currentYear - 2) && data.Month !== `Total ${currentYear - 2}`) ||
          (data.Year === (currentYear - 1) && data.Month !== `Total ${currentYear - 1}`) ||
          (data.Year === (currentYear + 1) && data.Month !== `Total ${currentYear + 1}`)
        ) {
          data.Month = data.Year
          return data;
        }
      });
    }else{
      data = posMonthdata.filter((data, index) => {
        var monthName = data.MonthFullName !==null && data.MonthFullName != undefined ? (data.MonthFullName).substring(0,3) : ''
        if (year !== currentYear - 1) {
          if (data.Year === currentYear - 1) {
            count = count + 1;
          }
        }
        if (year === data.Year && month.substring(0,3) === monthName) {
          data.highlightMe = true;
          this.setState({ ensureIndexVisible: index - count });
        }
        
        if (year === currentYear) {
          if (data.Month === `Total ${currentYear - 3}`) {
            data.Month = `► Total ${currentYear - 3}`;
          }
          if (data.Month === `Total ${currentYear - 2}`) {
            data.Month = `► Total ${currentYear - 2}`;
          }
          if (data.Month === `Total ${currentYear - 1}`) {
            data.Month = `► Total ${currentYear - 1}`;
          }
          if (data.Month === `Total ${currentYear + 1}`) {
            data.Month = `► Total ${currentYear + 1}`;
          }
          if (
            data.Year === currentYear ||
            data.Month === `► Total ${currentYear - 3}` ||
            data.Month === `► Total ${currentYear - 2}` ||
            data.Month === `► Total ${currentYear - 1}` ||
            data.Month === `► Total ${currentYear + 1}`
          ) {
            return data;
          }
        } else if (year === currentYear + 1) {
          if (data.Month === `Total ${currentYear - 3}`) {
            data.Month = `► Total ${currentYear - 3}`;
          }
          if (data.Month === `Total ${currentYear - 2}`) {
            data.Month = `► Total ${currentYear - 2}`;
          }
          if (data.Month === `Total ${currentYear - 1}`) {
            data.Month = `► Total ${currentYear - 1}`;
          }
          if (data.Month === `Total ${currentYear + 1}`) {
            data.Month = `▼ Total ${currentYear + 1}`;
          }
          if (
            data.Year === currentYear + 1 ||
            data.Year === currentYear ||
            data.Month === `► Total ${currentYear - 3}` ||
            data.Month === `► Total ${currentYear - 2}` ||
            data.Month === `► Total ${currentYear - 1}` ||
            data.Month === `▼ Total ${currentYear + 1}`
          ) {
            return data;
          }
          this.setState({
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
            showNextYearRows: true,
            showLastYearRows: false,
          });
        } else if (year === currentYear - 1) {
          if (data.Month === `Total ${currentYear - 3}`) {
            data.Month = `► Total ${currentYear - 3}`;
          }
          if (data.Month === `Total ${currentYear - 2}`) {
            data.Month = `► Total ${currentYear - 2}`;
          }
          if (data.Month === `Total ${currentYear - 1}`) {
            data.Month = `▼ Total ${currentYear - 1}`;
          }
          if (data.Month === `Total ${currentYear + 1}`) {
            data.Month = `► Total ${currentYear + 1}`;
          }
          if (
            data.Year === currentYear - 1 ||
            data.Year === currentYear ||
            data.Month === `► Total ${currentYear - 3}` ||
            data.Month === `► Total ${currentYear - 2}` ||
            data.Month === `▼ Total ${currentYear - 1}` ||
            data.Month === `► Total ${currentYear + 1}`
          ) {
            return data;
          }
          this.setState({
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
            showLastYearRows: true,
            showNextYearRows: false,
          });
        } else if (year === currentYear - 2) {
          if (data.Month === `Total ${currentYear - 3}`) {
            data.Month = `► Total ${currentYear - 3}`;
          }
          if (data.Month === `Total ${currentYear - 2}`) {
            data.Month = `▼ Total ${currentYear - 2}`;
          }
          if (data.Month === `Total ${currentYear - 1}`) {
            data.Month = `► Total ${currentYear - 1}`;
          }
          if (data.Month === `Total ${currentYear + 1}`) {
            data.Month = `► Total ${currentYear + 1}`;
          }
          if (
            data.Year === currentYear - 2 ||
            data.Year === currentYear ||
            data.Month === `► Total ${currentYear - 3}` ||
            data.Month === `▼ Total ${currentYear - 2}` ||
            data.Month === `► Total ${currentYear - 1}` ||
            data.Month === `► Total ${currentYear + 1}`
          ) {
            return data;
          }
          this.setState({
            show_LL2Y_Rows: false,
            show_LLY_Rows: true,
            showLastYearRows: false,
            showNextYearRows: false,
          });
        } else if (year === currentYear - 3) {
          if (data.Month === `Total ${currentYear - 3}`) {
            data.Month = `▼ Total ${currentYear - 3}`;
          }
          if (data.Month === `Total ${currentYear - 2}`) {
            data.Month = `► Total ${currentYear - 2}`;
          }
          if (data.Month === `Total ${currentYear - 1}`) {
            data.Month = `► Total ${currentYear - 1}`;
          }
          if (data.Month === `Total ${currentYear + 1}`) {
            data.Month = `► Total ${currentYear + 1}`;
          }
          if (
            data.Year === currentYear - 3 ||
            data.Month === `▼ Total ${currentYear - 3}` ||
            data.Month === `► Total ${currentYear - 2}` ||
            data.Month === `► Total ${currentYear - 1}` ||
            data.Month === `► Total ${currentYear + 1}`
          ) {
            return data;
          }
          this.setState({
            show_LL2Y_Rows: true,
            show_LLY_Rows: false,
            showLastYearRows: false,
            showNextYearRows: false,
          });
        }
      });
    }
    return data;
  }

  getHighlightedRow(updatedData, month) {
    let data = updatedData.map((data, index) => {
      let monthName = data.Month;
      if (
        monthName === `▼ Total ${currentYear - 1}` ||
        monthName === `► Total ${currentYear - 1}`
      ) {
        data.highlightMe = true;
      } else if (
        monthName === `▼ Total ${currentYear + 1}` ||
        monthName === `► Total ${currentYear + 1}`
      ) {
        data.highlightMe = true;
      } else if (
        monthName === `▼ Total ${currentYear - 2}` ||
        monthName === `► Total ${currentYear - 2}`
      ) {
        data.highlightMe = true;
      } else if (
        monthName === `▼ Total ${currentYear - 3}` ||
        monthName === `► Total ${currentYear - 3}`
      ) {
        data.highlightMe = true;
      }
      return data;
    });
    return data;
  }

  monthWiseCellClick = (params) => {
    var monththis = this;
    monththis.sendEvent("2", "clicked on Months row", "pos", "Pos Page");
    let {
      regionId,
      countryId,
      cityId,
      commonOD,

      gettingYear,

      gettingMonth,
      type,
    } = this.state;
    let selectedMonth = params.data.Month;
    let selectedYear = params.data.Year;
    let selectedQuarter = params.data.Quarter;

    var column = params.colDef.field;

    const posMonthDetails = this.state.posMonthDetails.map((d) => {
      d.highlightMe = false;
      return d;
    });
    params.api.updateRowData({ update: posMonthDetails });

    //Getting Clubbed Data

    ////// FOR YEAR /////

    if (column === "Month" && this.state.period === "yearly") {
      // let tempYear = `► Total ${currentYear - 3}`;
      if (selectedYear === currentYear - 1) {
        this.setState(
          {
            showLastYearRows: !this.state.showLastYearRows,
            showNextYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
            gettingYear: params.data.Year,
          }
        );
      } else if (selectedYear === currentYear + 1) {
        this.setState(
          {
            showNextYearRows: !this.state.showNextYearRows,
            showLastYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
            gettingYear: params.data.Year,
          }
        );
      } else if (selectedYear === currentYear - 2) {
        this.setState(
          {
            showNextYearRows: false,
            showLastYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: !this.state.show_LLY_Rows,
            gettingYear: params.data.Year,
          }
        );
      } else if (selectedYear === currentYear - 3) {
        this.setState(
          {
            showNextYearRows: false,
            showLastYearRows: false,
            show_LL2Y_Rows: !this.state.show_LL2Y_Rows,
            show_LLY_Rows: false,
            gettingYear: params.data.Year,
          }
        );
      } else if (selectedYear === currentYear) {
        monththis.setState({
          gettingYear: params.data.Year,
        });
      }
      const range = { from: { year: params.data.Year, month: 1 }, to: { year: params.data.Year, month: 1 } }
      window.localStorage.setItem('rangeValue', JSON.stringify(range))

      monththis.getDrillDownData(regionId, countryId, cityId, commonOD, type);
    }

    ///////// FOR MONTH ///////////
    else if (column === "Month" && this.state.period === "monthly") {
      if (selectedMonth.includes(`Total ${currentYear - 1}`)) {
        this.setState(
          {
            showLastYearRows: !this.state.showLastYearRows,
            showNextYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
          },
          () =>
            this.getLastYearClubbedData(
              this.state.showLastYearRows,
              selectedMonth,
              0
            )
        );
      } else if (selectedMonth.includes(`Total ${currentYear + 1}`)) {
        this.setState(
          {
            showNextYearRows: !this.state.showNextYearRows,
            showLastYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
          },
          () =>
            this.getNextYearClubbedData(
              this.state.showNextYearRows,
              selectedMonth,
              0
            )
        );
      } else if (selectedMonth.includes(`Total ${currentYear - 2}`)) {
        this.setState(
          {
            showNextYearRows: false,
            showLastYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: !this.state.show_LLY_Rows,
          },
          () =>
            this.getLLYClubbedData(this.state.show_LLY_Rows, selectedMonth, 0)
        );
      } else if (selectedMonth.includes(`Total ${currentYear - 3}`)) {
        this.setState(
          {
            showNextYearRows: false,
            showLastYearRows: false,
            show_LL2Y_Rows: !this.state.show_LL2Y_Rows,
            show_LLY_Rows: false,
          },
          () =>
            this.getLL2YClubbedData(this.state.show_LL2Y_Rows, selectedMonth, 0)
        );
      } else {
        monththis.setState({
          gettingMonth: params.data.MonthFullName,
          gettingYear: params.data.Year,
        });
      }
      

      if (column === "Month" && !selectedMonth.includes(`Total`)) {
        monththis.setState({
          loading2: true,
          drillDownData: [],
          drillDownTotalData: [],
        });
        const range = { from: { year: params.data.Year, month: window.monthNameToNum(params.data.MonthFullName) }, to: { year: params.data.Year, month: window.monthNameToNum(params.data.MonthFullName) } }
        window.localStorage.setItem('rangeValue', JSON.stringify(range))
        monththis.getDrillDownData(regionId, countryId, cityId, commonOD, type);
      }
    }

    ///////////FOR QUARTER /////////
    else if (column === "Month" && this.state.period === "quarterly") {
      if (selectedMonth.includes(`Total ${currentYear - 1}`)) {
        this.setState(
          {
            showLastYearRows: !this.state.showLastYearRows,
            showNextYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
          },
          () =>
            this.getLastYearClubbedData(
              this.state.showLastYearRows,
              selectedMonth,
              0
            )
        );
      } else if (selectedMonth.includes(`Total ${currentYear + 1}`)) {
        this.setState(
          {
            showNextYearRows: !this.state.showNextYearRows,
            showLastYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
          },
          () =>
            this.getNextYearClubbedData(
              this.state.showNextYearRows,
              selectedMonth,
              0
            )
        );
      } else if (selectedMonth.includes(`Total ${currentYear - 2}`)) {
        this.setState(
          {
            showNextYearRows: false,
            showLastYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: !this.state.show_LLY_Rows,
          },
          () =>
            this.getLLYClubbedData(this.state.show_LLY_Rows, selectedMonth, 0)
        );
      } else if (selectedMonth.includes(`Total ${currentYear - 3}`)) {
        this.setState(
          {
            showNextYearRows: false,
            showLastYearRows: false,
            show_LL2Y_Rows: !this.state.show_LL2Y_Rows,
            show_LLY_Rows: false,
          },
          () =>
            this.getLL2YClubbedData(this.state.show_LL2Y_Rows, selectedMonth, 0)
        );
      } else {
        monththis.setState({
          gettingMonth: params.data.MonthFullName,
          gettingYear: params.data.Year,
        });
      }

      if (column === "Month" && !selectedMonth.includes(`Total`)) {
        monththis.setState({
          loading2: true,
          drillDownData: [],
          drillDownTotalData: [],
        });
        const range = { from: { year: params.data.Year, month: params.data.MonthNum }, to: { year: params.data.Year, month: params.data.MonthNum } }
        window.localStorage.setItem('rangeValue', JSON.stringify(range))
        monththis.getDrillDownData(regionId, countryId, cityId, commonOD, type);
      }
    }
    else if (column === "Quarter") {
      if (
        selectedQuarter.includes(`Total ${currentYear - 1}`) &&
        selectedQuarter.includes(`Total ${currentYear - 1}`)
      ) {
        this.setState(
          {
            showLastYearRows: !this.state.showLastYearRows,
            showNextYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
          },
          () =>
            this.getLastYearClubbedData(
              this.state.showLastYearRows,
              selectedMonth
            )
        );
      } else if (
        selectedQuarter.includes(`Total ${currentYear + 1}`) &&
        selectedQuarter.includes(`Total ${currentYear + 1}`)
      ) {
        this.setState(
          {
            showNextYearRows: !this.state.showNextYearRows,
            showLastYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: false,
          },
          () =>
            this.getNextYearClubbedData(
              this.state.showNextYearRows,
              selectedMonth
            )
        );
      } else if (
        selectedQuarter.includes(`Total ${currentYear - 2}`) &&
        selectedQuarter.includes(`Total ${currentYear - 2}`)
      ) {
        this.setState(
          {
            showNextYearRows: false,
            showLastYearRows: false,
            show_LL2Y_Rows: false,
            show_LLY_Rows: !this.state.show_LLY_Rows,
          },
          () => this.getLLYClubbedData(this.state.show_LLY_Rows, selectedMonth)
        );
      } else if (
        selectedQuarter.includes(`Total ${currentYear - 3}`) &&
        selectedQuarter.includes(`Total ${currentYear - 3}`)
      ) {
        this.setState(
          {
            showNextYearRows: false,
            showLastYearRows: false,
            show_LL2Y_Rows: !this.state.show_LL2Y_Rows,
            show_LLY_Rows: false,
          },
          () =>
            this.getLL2YClubbedData(this.state.show_LL2Y_Rows, selectedMonth)
        );
      } else {
        monththis.setState({
          gettingMonth: params.data.MonthFullName,
          gettingYear: params.data.Year,
        });
      }

      if (column === "Month" || column === "Year" || column === "Quarter") {
        monththis.setState({
          loading2: true,
          drillDownData: [],
          drillDownTotalData: [],
        });
        monththis.getDrillDownData(regionId, countryId, cityId, commonOD, type);
      }
    }

    ///////////////////////////////

    // const range = {
    //   from: {
    //     year: params.data.Year,
    //     month: window.monthNameToNum(params.data.MonthFullName),
    //   },
    //   to: {
    //     year: params.data.Year,
    //     month: window.monthNameToNum(params.data.MonthFullName),
    //   },
    // };
    // window.localStorage.setItem("rangeValue", JSON.stringify(range));
    // }

    // if (
    //   column === "Month" ||
    //   column === "Year"
    //   // &&
    //   // !selectedMonth.includes("Total")
    // ) {
    //   monththis.setState({
    //     loading2: true,
    //     drillDownData: [],
    //     drillDownTotalData: [],
    //   });
    //   monththis.getDrillDownData(regionId, countryId, cityId, commonOD, type);
    // } else {
    //   monththis.setState({
    //     loading2: true,
    //     drillDownData: [],
    //     drillDownTotalData: [],
    //   });
    //   monththis.getDrillDownData(regionId, countryId, cityId, commonOD, type);
    // }
  };

  getLLYClubbedData(show_LLY_Rows, selectedMonth) {
    
    if (show_LLY_Rows) {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `► Total ${currentYear - 2}`) {
          d.Month = `▼ Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }
        if (
          d.Year === currentYear - 2 ||
          d.Year === currentYear ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `▼ Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    } else {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }

        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === currentYear ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    }
  }
  getLL2YClubbedData(show_LL2Y_Rows, selectedMonth) {
    
    if (show_LL2Y_Rows) {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `► Total ${currentYear - 3}`) {
          d.Month = `▼ Total ${currentYear - 3}`;
        }
        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }
        if (
          d.Year === currentYear - 3 ||
          d.Year === currentYear ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `▼ Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    } else {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }

        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === currentYear ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    }
  }

  getLastYearClubbedData(showLastYearRows, selectedMonth, isYear) {
    // if (isYear === 0) {
    if (showLastYearRows) {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `► Total ${currentYear - 1}`) {
          d.Month = `▼ Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === currentYear - 1 ||
          d.Year === currentYear ||
          d.Month === `▼ Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    } else {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === currentYear ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    }
    // }
    // else if (isYear === 1) {
    //   if (showLastYearRows) {
    //     const updatedMonthData = monthData.filter((d) => {
    //       if (d.Year === `► Total ${currentYear - 1}`) {
    //         d.Year = `▼ Total ${currentYear - 1}`;
    //       }
    //       if (d.Year === `▼ Total ${currentYear + 1}`) {
    //         d.Year = `► Total ${currentYear + 1}`;
    //       }
    //       if (d.Year === `▼ Total ${currentYear - 2}`) {
    //         d.Year = `► Total ${currentYear - 2}`;
    //       }
    //       if (d.Year === `▼ Total ${currentYear - 3}`) {
    //         d.Year = `► Total ${currentYear - 3}`;
    //       }
    //       if (
    //         d.Year === currentYear - 1 ||
    //         d.Year === currentYear ||
    //         d.Year === `▼ Total ${currentYear - 1}` ||
    //         d.Year === `► Total ${currentYear + 1}` ||
    //         d.Year === `► Total ${currentYear - 2}` ||
    //         d.Year === `► Total ${currentYear - 3}`
    //       ) {
    //         return d;
    //       }
    //     });
    //     console.log(updatedMonthData, "KKKKKK");
    //     this.setState({
    //       posMonthDetails: this.getHighlightedRow(
    //         updatedMonthData,
    //         selectedMonth
    //       ),
    //     });
    //   } else {
    //     const updatedMonthData = monthData.filter((d) => {
    //       if (d.Year === `▼ Total ${currentYear - 1}`) {
    //         d.Year = `► Total ${currentYear - 1}`;
    //       }
    //       if (d.Year === `▼ Total ${currentYear + 1}`) {
    //         d.Year = `► Total ${currentYear + 1}`;
    //       }
    //       if (d.Year === `▼ Total ${currentYear - 2}`) {
    //         d.Year = `► Total ${currentYear - 2}`;
    //       }
    //       if (d.Year === `▼ Total ${currentYear - 3}`) {
    //         d.Year = `► Total ${currentYear - 3}`;
    //       }
    //       if (
    //         d.Year === currentYear ||
    //         d.Year === `► Total ${currentYear - 1}` ||
    //         d.Year === `► Total ${currentYear + 1}` ||
    //         d.Year === `► Total ${currentYear - 2}` ||
    //         d.Year === `► Total ${currentYear - 3}`
    //       ) {
    //         return d;
    //       }
    //     });
    //     console.log(updatedMonthData, "YYYY");
    //     this.setState({
    //       posMonthDetails: this.getHighlightedRow(
    //         updatedMonthData,
    //         selectedMonth
    //       ),
    //     });
    //   }
    // }
  }

  getNextYearClubbedData(showNextYearRows, selectedMonth) {
    if (showNextYearRows) {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `► Total ${currentYear + 1}`) {
          d.Month = `▼ Total ${currentYear + 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === currentYear + 1 ||
          d.Year === currentYear ||
          d.Month === `▼ Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    } else {
      const updatedMonthData = monthData.filter((d) => {
        if (d.Month === `▼ Total ${currentYear + 1}`) {
          d.Month = `► Total ${currentYear + 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 1}`) {
          d.Month = `► Total ${currentYear - 1}`;
        }
        if (d.Month === `▼ Total ${currentYear - 2}`) {
          d.Month = `► Total ${currentYear - 2}`;
        }
        if (d.Month === `▼ Total ${currentYear - 3}`) {
          d.Month = `► Total ${currentYear - 3}`;
        }
        if (
          d.Year === currentYear ||
          d.Month === `► Total ${currentYear + 1}` ||
          d.Month === `► Total ${currentYear - 1}` ||
          d.Month === `► Total ${currentYear - 2}` ||
          d.Month === `► Total ${currentYear - 3}`
        ) {
          return d;
        }
      });
      this.setState({
        posMonthDetails: this.getHighlightedRow(
          updatedMonthData,
          selectedMonth
        ),
      });
    }
  }

  regionCellClick = (params) => {
    if (this.state.type !== "Null") {
      return false;
    }
    var self = this;
    self.sendEvent(
      "2",
      "clicked on Region drill down",
      "marketShare",
      "MarketShare Page"
    );
    let { regionId, countryId, cityId, commonOD, getCabinValue } = this.state;

    var column = params.colDef.field;
    var headName = params.colDef.headerName;
    var selectedData = `'${params.data.firstColumnName}'`;
    var selectedDataWQ = params.data.firstColumnName;
    var selectedTitle = params.colDef.headerName;

    let found = false;

    if (column === "Avail") {
      if (!found) {
        if (regionId === "*") {
          self.getAvailabilityData(
            selectedData,
            countryId,
            cityId,
            commonOD,
            getCabinValue,
            "Null",
            "Null"
          );
        } else if (countryId === "*") {
          self.getAvailabilityData(
            regionId,
            selectedData,
            cityId,
            commonOD,
            getCabinValue,
            "Null",
            "Null"
          );
        } else if (cityId === "*") {
          self.getAvailabilityData(
            regionId,
            countryId,
            cityId,
            commonOD,
            getCabinValue,
            "Null",
            "Null"
          );
        } else if (commonOD === "*") {
          self.getAvailabilityData(
            regionId,
            countryId,
            cityId,
            selectedData,
            getCabinValue,
            "Null",
            "Null"
          );
        } else {
          self.getAvailabilityData(
            regionId,
            countryId,
            cityId,
            commonOD,
            selectedData,
            "Null",
            "Null"
          );
        }
      }
    } else if (column === "Actual_A") {
      this.getInfareGraphData(params);
    } else if (
      column === "firstColumnName" &&
      headName === "City" &&
      countryId !== "*"
    ) {
      this.setState(
        {
          cityId: params.data.firstColumnName,
          cityWiseModalSwitchGraph: false,
          cityWisePeriod: "monthly",
        },
        () => {
          this.get_Pax_J9_Graph(selectedData);
        }
      );
    } else if (column === "MS_J9") {
      this.setState(
        {
          cityId: params.data.firstColumnName,
          cityFrequencyModalSwitchGraph: false,
          cityFrequencyPeriod: "monthly",
        },
        () => {
          this.getCityGraph(params);
        }
      );
    } else if (column === "Sector_J9")
      this.setState(
        {
          cityId: params.data.firstColumnName,
          cityFrequencyModalSwitchGraph: false,
          cityFrequencyPeriod: "monthly",
        },
        () => {
          this.get_MS_J9_Graph(params);
        }
      );
    else if (column === "firstColumnName" && selectedDataWQ !== "null") {
      if (!found) {
        this.storeValuesToLS(
          regionId,
          countryId,
          cityId,
          commonOD,
          getCabinValue,
          selectedDataWQ
        );

        if (selectedTitle !== "Compartment") {
          self.setState({ selectedData });
          bcData.push({ val: selectedDataWQ, title: selectedTitle });
        }

        if (regionId === "*") {
          self.getMarketShareMonthDrillDownData(
            selectedData,
            countryId,
            cityId,
            commonOD
          );
        } else if (countryId === "*") {
          self.getMarketShareMonthDrillDownData(
            regionId,
            selectedData,
            cityId,
            commonOD
          );
        } else if (cityId === "*") {
          self.getMarketShareMonthDrillDownData(
            regionId,
            countryId,
            selectedData,
            commonOD
          );
        } else if (commonOD === "*") {
          self.getMarketShareMonthDrillDownData(
            regionId,
            countryId,
            cityId,
            selectedData
          );
        }
      }
    }
  };

  //City WIse Graph

  getCityGraph = () => {
    var self = this;
    const {
      gettingMonth,
      gettingYear,
      regionId,
      countryId,
      cityId,
      cityWisePeriod,
      cityWiseModalSwitchGraph,
    } = this.state;
    if (!this.state.cityWiseModalSwitchGraph) {
      apiServices
        .get_City_Table(
          gettingMonth,
          gettingYear,
          regionId,
          countryId,
          cityId,
          cityWisePeriod
        )
        .then(function (result) {
          var columnName = result[0].columnName;
          var J9Data = result[0].data;
          self.setState({
            cityWiseModal: true,
            cityWiseModalData: J9Data,
            cityWiseModalColumn: columnName,
          });
        });
    } else {
      apiServices
        .get_City_Graph(
          gettingMonth,
          gettingYear,
          regionId,
          countryId,
          cityId,
          cityWisePeriod
        )
        .then(function (result) {
          self.setState(
            {
              cityWiseModal: true,
              cityWiseModalData: result[0].data,
              cityWiseModalColumn: result[0].airlines,
            },
            () => {
              self.renderCityWiseLineGraphs(result[0].data);
            }
          );
        });
    }
  };

  onChangeCityWisePeriod = (e) => {
    e.stopPropagation();
    this.setState(
      {
        cityWisePeriod: e.target.value,
      },
      () => {
        this.getCityGraph();
      }
    );
  };

  toggleCityWiseGraphs = () => {
    this.setState(
      {
        cityWiseModalSwitchGraph: !this.state.cityWiseModalSwitchGraph,
        cityWiseModalData: [],
        cityWiseModalColumn: [],
      },
      () => this.getCityGraph()
    );
  };

  renderCityWiseModal() {
    
    const {
      cityWiseModalSwitchGraph,
      cityWiseModalColumn,
      cityWisePeriod,
      gettingYear,
    } = this.state;
    const graphType = cityWisePeriod === "monthly" ? "Month" : "Year";
    return (
      <Modal
        show={this.state.cityWiseModal}
        onHide={() =>
          this.setState({
            cityWiseModal: false,
            cityWiseGraph: "Table",
          })
        }
        aria-labelledby="ModalHeader"
        className="cityFrequencyTable"
      >
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">
            {this.state.cityWisePeriod === "monthly"
              ? "City Wise Monthly"
              : "City Wise Yearly"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%" }}>
            <div className="cityFrequencyBodyTop">
              <h3
                className="headingPOS"
                title={Constant.removeQuotes(this.state.sectorId)}
              >
                {`City : ${Constant.removeQuotes(this.state.cityId)}`}
              </h3>

              {this.state.cityWisePeriod === "monthly" ? (
                <h4>{`Year : ${this.state.gettingYear}`}</h4>
              ) : (
                ""
              )}
              <div style={{ display: "flex" }}>
                <select
                  className="form-control cabinselect pos-route-dropdown"
                  onChange={(e) => this.onChangeCityWisePeriod(e)}
                >
                  <option value="monthly" selected={true}>
                    Monthly
                  </option>
                  <option value="yearly">Yearly</option>
                </select>
                <div className="Switch_btn">
                  <button
                    style={{
                      fontSize: "19px",
                      backgroundColor: "orangered",
                      color: "white",
                      marginBottom: "0px",
                      marginLeft: "5px",
                    }}
                    onClick={() => this.toggleCityWiseGraphs()}
                  >
                    <i className="fa fa-line-chart"></i>
                  </button>
                </div>
              </div>
            </div>
            {cityWiseModalSwitchGraph == true ? (
              <div className="x_panel tile">
                <div className="x_title" style={{ borderBottom: "None" }}>
                  <div
                    className="nav navbar-right panel_toolbox"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="info">
                      <MultiLineChartLegends
                        i={true}
                        colors={this.state.cityWiseGraphColors}
                        selectedTrend={graphType}
                        data={cityWiseModalColumn}
                      />
                    </div>
                  </div>
                </div>

                <div className="centered-graph">
                  <div
                    id="cityfrequency_multiline"
                    style={{ padding: "5px", "padding-bottom": "0px" }}
                  ></div>
                  <MultiLineChartLegends
                    colors={this.state.cityWiseGraphColors}
                    selectedTrend={graphType}
                    data={cityWiseModalColumn}
                  />
                </div>
              </div>
            ) : (
              <DataTableComponent
                rowData={this.state.cityWiseModalData}
                columnDefs={this.state.cityWiseModalColumn}
                route={true}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  renderCityWiseLineGraphs = (data) => {
    const selectedcity = this.state.cityId;

    var Data = d3
      .nest()
      .key(function (d) {
        return d.AirlineName;
      })
      .entries(data);

    var margin = {
        top: 10,
        right: 30,
        bottom: 15,
        left: 70,
      },
      width = 610 - margin.left - margin.right,
      height = 260 - margin.top - margin.bottom;

    var x = d3.scale.ordinal().rangeRoundBands([0, width]);

    var y = d3.scale.linear().range([height, 0]);

    var colors = this.state.cityWiseGraphColors;

    // Do not include a domain
    var color = d3.scale.ordinal().range(colors);

    var xAxis = d3.svg
      .axis()
      .scale(x)
      .orient("bottom")
      .tickFormat(function (d) {
        return d;
      });

    var yAxis = d3.svg
      .axis()
      .scale(y)
      .orient("left")
      .ticks(6)
      .tickFormat(d3.format("s"));

    // xData gives an array of distinct 'Weeks' for which trends chart is going to be made.
    const result = Data.map((obj) => obj.values.length).indexOf(
      Math.max(...Data.map((obj) => obj.values.length))
    );

    var xData = Data[result].values.map(function (d) {
      return d.Xaxis;
    });

    var line = d3.svg
      .line()
      // .interpolate("basis")
      .x(function (d) {
        return x(d.Xaxis) + x.rangeBand() / 2;
      })
      .y(function (d) {
        return y(d.Pax);
      });

    d3.select("#infare").remove();

    var svg = d3
      .select("#cityfrequency_multiline")
      .append("svg")
      .attr("viewBox", "0 0 600 280")
      .attr("id", "infare")
      .attr("style", "overflow:visible")
      .attr("preserveAspectRatio", "none")
      .attr("xmlns", "http://www.w3.org/2000/svg")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //Colors
    color.domain(
      Data.map(function (d) {
        return d.key;
      })
    );

    x.domain(xData);

    var valueMax = d3.max(Data, function (r) {
      return d3.max(r.values, function (d) {
        return d.Pax;
      });
    });
    var valueMin = d3.min(Data, function (r) {
      return d3.min(r.values, function (d) {
        return d.Pax;
      });
    });

    y.domain([valueMin, valueMax]);

    //Drawing X Axis
    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    // Drawing Horizontal grid lines.
    svg
      .append("g")
      .attr("class", "GridX")
      .selectAll("line.grid")
      .data(y.ticks())
      .enter()
      .append("line")
      .attr({
        class: "grid",
        x1: x(xData[0]),
        x2: x(xData[xData.length - 1]) + x.rangeBand() / 2,
        y1: function (d) {
          return y(d);
        },
        y2: function (d) {
          return y(d);
        },
      });

    // Drawing Y Axis
    svg
      .append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dx", "-5em")
      .attr("dy", "-4em")
      .style("text-anchor", "end")
      .style(
        "font-family",
        '"Helvetica Neue",Roboto,Arial,"Droid Sans",sans-serif'
      )
      .style("font-size", "14px")
      .style("font-weight", "500");

    // Drawing Lines for each segments
    var segment = svg
      .selectAll(".segment")
      .data(Data)
      .enter()
      .append("g")
      .attr("class", "segment");

    segment
      .append("path")
      .attr("id", function (d) {
        return d.key;
      })
      .attr("visible", 1)
      .attr("d", function (d) {
        return line(d.values);
      })
      .style("stroke", function (d) {
        // if (d.key === "2018") {
        // colorChange();
        // } else {
        return color(d.key);
        //  }
      })
      // .attr("stroke", "green")
      .attr("stroke-width", 2)
      .attr("fill", "none")
      .attr("class", "line");

    function colorChange() {
      segment
        .append("path")
        .attr("d", function (d) {
          return line(
            d.values.filter(function (d) {
              return d.City === selectedcity;
            })
          );
        })
        .style("stroke", "#64bbe3")
        .attr("stroke-width", 2)
        .attr("fill", "none")
        .attr("class", "line");

      // segment.append("path")
      //     .attr("d", function (d) {
      //         return line(d.values.filter(function (d) {
      //             return d.DataType === "Forecast";
      //         }))
      //     })
      //     .attr("stroke", "#eed202")
      //     .attr("stroke-width", 2)
      //     .attr("fill", "none");
    }

    // Creating Dots on line
    segment
      .selectAll("dot")
      .data(function (d) {
        return d.values;
      })
      .enter()
      .append("circle")
      .attr("r", 5)
      .attr("cx", function (d) {
        return x(d.Xaxis) + x.rangeBand() / 2;
      })
      .attr("cy", function (d) {
        return y(d.Pax);
      })
      .style("stroke", "white")
      .style("cursor", "pointer")
      .style("fill", function (d) {
        // if (d.DataType === "Actuals") {
        return color(this.parentNode.__data__.key);
        // } else {
        // return "#eed202";
        // }
      })
      .on("mouseover", mouseover)
      .on("mousemove", function (d) {
        divToolTip
          .text(
            this.parentNode.__data__.key + " : " + window.numberFormat(d.Pax, 2)
          ) // here we using numberFormat function from Dashboard-indicator.js
          .style("left", d3.event.pageX + 15 + "px")
          .style("top", d3.event.pageY - 10 + "px");
      })
      .on("mouseout", mouseout);

    segment
      .append("text")
      .datum(function (d) {
        return {
          avgname: d.key,
          avgfareData: d.values[d.values.length - 1],
        };
      })
      .attr("transform", function (d) {
        var xpos = x(d.avgfareData.Xaxis) + x.rangeBand() / 2;
        return "translate(" + xpos + "," + y(d.avgfareData.Pax) + ")";
      })
      .attr("x", -15)
      .attr("y", -15)
      .attr("dy", ".38em")
      .attr("class", "segmentText")
      .style("font-size", "0px")
      .style("font-weight", "bold")
      .attr("Segid", function (d) {
        return d.avgname;
      })
      .text(function (d) {
        return d.avgname;
      });
    // Adding Tooltip
    var divToolTip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("color", "white")
      .style("background", "rgb(42, 63, 84)")
      .style("padding", "5px")
      .style("opacity", 1e-6);

    function mouseover() {
      divToolTip
        .transition()
        .attr("class", "padding tooltip")
        .duration(500)
        .style("opacity", 1);
    }

    function mouseout() {
      divToolTip
        .transition()
        .duration(500)
        .style("padding", "0px !important")
        .style("opacity", 1e-6);
    }

    /* Add 'curtain' rectangle to hide entire graph */
    // var curtain = svg.append('rect')
    //     .attr('x', -1 * width)
    //     .attr('y', -1 * height)
    //     .attr('height', height + 25)
    //     .attr('width', width + 80)
    //     .attr('class', 'curtain')
    //     .attr('transform', 'rotate(180)')
    //     .style('fill', '#2e303f')

    /* Optionally add a guideline */
    var guideline = svg
      .append("line")
      .attr("stroke", "#333")
      .attr("stroke-width", 0)
      .attr("class", "guide")
      .attr("x1", 1)
      .attr("y1", 1)
      .attr("x2", 1)
      .attr("y2", height);
  };

  //MS J9 Graph
  get_MS_J9_Graph = () => {
    var self = this;
    const {
      gettingMonth,
      gettingYear,
      regionId,
      countryId,
      cityId,
      cityFrequencyPeriod,
      cityFrequencyModalSwitchGraph,
    } = this.state;
    if (!this.state.cityFrequencyModalSwitchGraph) {
      apiServices
        .get_MS_J9_Table(
          gettingMonth,
          gettingYear,
          regionId,
          countryId,
          cityId,
          cityFrequencyPeriod
        )
        .then(function (result) {
          var columnName = result[0].columnName;
          var J9Data = result[0].data;
          self.setState({
            cityFrequencyModal: true,
            cityFrequencyModalData: J9Data,
            cityFrequencyModalColumn: columnName,
          });
        });
    } else {
      apiServices
        .get_MS_J9_Graph(
          gettingMonth,
          gettingYear,
          regionId,
          countryId,
          cityId,
          cityFrequencyPeriod
        )
        .then(function (result) {
          self.setState(
            {
              cityFrequencyModal: true,
              cityFrequencyModalData: result[0].data,
              cityFrequencyModalColumn: result[0].airlines,
            },
            () => {
              self.renderCityFrequencyLineGraphs(result[0].data);
            }
          );
        });
    }
  };

  onChangeCityFrequencyPeriod = (e) => {
    e.stopPropagation();
    this.setState(
      {
        cityFrequencyPeriod: e.target.value,
      },
      () => {
        this.get_MS_J9_Graph();
      }
    );
  };

  toggleCityFrequencyGraphs = () => {
    this.setState(
      {
        cityFrequencyModalSwitchGraph:
          !this.state.cityFrequencyModalSwitchGraph,
        cityFrequencyModalData: [],
        cityFrequencyModalColumn: [],
      },
      () => this.get_MS_J9_Graph()
    );
  };

  renderCityFrequencyModal() {
    
    const {
      cityFrequencyModalSwitchGraph,
      cityFrequencyModalColumn,
      cityFrequencyPeriod,
      gettingYear,
    } = this.state;
    // const colors = this.state.cityFrequencyGraphColors;
    const graphType = cityFrequencyPeriod === "monthly" ? "Month" : "Year";
    return (
      <Modal
        show={this.state.cityFrequencyModal}
        onHide={() =>
          this.setState({
            cityFrequencyModal: false,
            cityFrequencyGraph: "Table",
          })
        }
        aria-labelledby="ModalHeader"
        className="cityFrequencyTable"
      >
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">
            {this.state.cityFrequencyPeriod === "monthly"
              ? "City Frequency Monthly"
              : "City Frequency Yearly"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%" }}>
            <div className="cityFrequencyBodyTop">
              <h3
                className="headingPOS"
                title={Constant.removeQuotes(this.state.sectorId)}
              >
                {`City : ${Constant.removeQuotes(this.state.cityId)}`}
              </h3>
              {this.state.cityFrequencyPeriod === "monthly" ? (
                <h4>{`Year : ${this.state.gettingYear}`}</h4>
              ) : (
                ""
              )}
              <div style={{ display: "flex" }}>
                <select
                  className="form-control cabinselect pos-route-dropdown"
                  onChange={(e) => this.onChangeCityFrequencyPeriod(e)}
                >
                  <option value="monthly" selected={true}>
                    Monthly
                  </option>
                  <option value="yearly">Yearly</option>
                </select>
                <div className="Switch_btn">
                  <button
                    style={{
                      fontSize: "19px",
                      backgroundColor: "orangered",
                      color: "white",
                      marginBottom: "0px",
                      marginLeft: "5px",
                    }}
                    onClick={() => this.toggleCityFrequencyGraphs()}
                  >
                    <i className="fa fa-line-chart"></i>
                  </button>
                </div>
              </div>
            </div>
            {cityFrequencyModalSwitchGraph == true ? (
              <div className="x_panel tile">
                <div className="x_title" style={{ borderBottom: "None" }}>
                  <div
                    className="nav navbar-right panel_toolbox"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="info">
                      <MultiLineChartLegends
                        i={true}
                        colors={this.state.cityFrequencyGraphColors}
                        selectedTrend={graphType}
                        data={cityFrequencyModalColumn}
                      />
                    </div>
                  </div>
                </div>

                <div className="centered-graph">
                  <div
                    id="cityfrequency_multiline"
                    style={{ padding: "5px", "padding-bottom": "0px" }}
                  ></div>
                  <MultiLineChartLegends
                    colors={this.state.cityFrequencyGraphColors}
                    selectedTrend={graphType}
                    data={cityFrequencyModalColumn}
                  />
                </div>
              </div>
            ) : (
              <DataTableComponent
                rowData={this.state.cityFrequencyModalData}
                columnDefs={this.state.cityFrequencyModalColumn}
                route={true}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  renderCityFrequencyLineGraphs = (data) => {
    const selectedcity = this.state.cityId;

    var Data = d3
      .nest()
      .key(function (d) {
        return d.AirlineName;
      })
      .entries(data);

    var margin = {
        top: 10,
        right: 30,
        bottom: 15,
        left: 70,
      },
      width = 610 - margin.left - margin.right,
      height = 260 - margin.top - margin.bottom;

    var x = d3.scale.ordinal().rangeRoundBands([0, width]);

    var y = d3.scale.linear().range([height, 0]);

    var colors = this.state.cityFrequencyGraphColors;

    // Do not include a domain
    var color = d3.scale.ordinal().range(colors);

    var xAxis = d3.svg
      .axis()
      .scale(x)
      .orient("bottom")
      .tickFormat(function (d) {
        return d;
      });

    var yAxis = d3.svg
      .axis()
      .scale(y)
      .orient("left")
      .ticks(6)
      .tickFormat(d3.format("s"));

    // xData gives an array of distinct 'Weeks' for which trends chart is going to be made.
    const result = Data.map((obj) => obj.values.length).indexOf(
      Math.max(...Data.map((obj) => obj.values.length))
    );

    var xData = Data[result].values.map(function (d) {
      return d.Xaxis;
    });

    var line = d3.svg
      .line()
      // .interpolate("basis")
      .x(function (d) {
        return x(d.Xaxis) + x.rangeBand() / 2;
      })
      .y(function (d) {
        return y(d.Pax);
      });

    d3.select("#infare").remove();

    var svg = d3
      .select("#cityfrequency_multiline")
      .append("svg")
      .attr("viewBox", "0 0 600 280")
      .attr("id", "infare")
      .attr("style", "overflow:visible")
      .attr("preserveAspectRatio", "none")
      .attr("xmlns", "http://www.w3.org/2000/svg")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //Colors
    color.domain(
      Data.map(function (d) {
        return d.key;
      })
    );

    x.domain(xData);

    var valueMax = d3.max(Data, function (r) {
      return d3.max(r.values, function (d) {
        return d.Pax;
      });
    });
    var valueMin = d3.min(Data, function (r) {
      return d3.min(r.values, function (d) {
        return d.Pax;
      });
    });

    y.domain([valueMin, valueMax]);

    //Drawing X Axis
    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    // Drawing Horizontal grid lines.
    svg
      .append("g")
      .attr("class", "GridX")
      .selectAll("line.grid")
      .data(y.ticks())
      .enter()
      .append("line")
      .attr({
        class: "grid",
        x1: x(xData[0]),
        x2: x(xData[xData.length - 1]) + x.rangeBand() / 2,
        y1: function (d) {
          return y(d);
        },
        y2: function (d) {
          return y(d);
        },
      });

    // Drawing Y Axis
    svg
      .append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dx", "-5em")
      .attr("dy", "-4em")
      .style("text-anchor", "end")
      .style(
        "font-family",
        '"Helvetica Neue",Roboto,Arial,"Droid Sans",sans-serif'
      )
      .style("font-size", "14px")
      .style("font-weight", "500");

    // Drawing Lines for each segments
    var segment = svg
      .selectAll(".segment")
      .data(Data)
      .enter()
      .append("g")
      .attr("class", "segment");

    segment
      .append("path")
      .attr("id", function (d) {
        return d.key;
      })
      .attr("visible", 1)
      .attr("d", function (d) {
        return line(d.values);
      })
      .style("stroke", function (d) {
        // if (d.key === "2018") {
        // colorChange();
        // } else {
        return color(d.key);
        //  }
      })
      // .attr("stroke", "green")
      .attr("stroke-width", 2)
      .attr("fill", "none")
      .attr("class", "line");

    function colorChange() {
      segment
        .append("path")
        .attr("d", function (d) {
          return line(
            d.values.filter(function (d) {
              return d.City === selectedcity;
            })
          );
        })
        .style("stroke", "#64bbe3")
        .attr("stroke-width", 2)
        .attr("fill", "none")
        .attr("class", "line");

      // segment.append("path")
      //     .attr("d", function (d) {
      //         return line(d.values.filter(function (d) {
      //             return d.DataType === "Forecast";
      //         }))
      //     })
      //     .attr("stroke", "#eed202")
      //     .attr("stroke-width", 2)
      //     .attr("fill", "none");
    }

    // Creating Dots on line
    segment
      .selectAll("dot")
      .data(function (d) {
        return d.values;
      })
      .enter()
      .append("circle")
      .attr("r", 5)
      .attr("cx", function (d) {
        return x(d.Xaxis) + x.rangeBand() / 2;
      })
      .attr("cy", function (d) {
        return y(d.Pax);
      })
      .style("stroke", "white")
      .style("cursor", "pointer")
      .style("fill", function (d) {
        // if (d.DataType === "Actuals") {
        return color(this.parentNode.__data__.key);
        // } else {
        // return "#eed202";
        // }
      })
      .on("mouseover", mouseover)
      .on("mousemove", function (d) {
        divToolTip
          .text(
            this.parentNode.__data__.key + " : " + window.numberFormat(d.Pax, 2)
          ) // here we using numberFormat function from Dashboard-indicator.js
          .style("left", d3.event.pageX + 15 + "px")
          .style("top", d3.event.pageY - 10 + "px");
      })
      .on("mouseout", mouseout);

    segment
      .append("text")
      .datum(function (d) {
        return {
          avgname: d.key,
          avgfareData: d.values[d.values.length - 1],
        };
      })
      .attr("transform", function (d) {
        var xpos = x(d.avgfareData.Xaxis) + x.rangeBand() / 2;
        return "translate(" + xpos + "," + y(d.avgfareData.Pax) + ")";
      })
      .attr("x", -15)
      .attr("y", -15)
      .attr("dy", ".38em")
      .attr("class", "segmentText")
      .style("font-size", "0px")
      .style("font-weight", "bold")
      .attr("Segid", function (d) {
        return d.avgname;
      })
      .text(function (d) {
        return d.avgname;
      });
    // Adding Tooltip
    var divToolTip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("color", "white")
      .style("background", "rgb(42, 63, 84)")
      .style("padding", "5px")
      .style("opacity", 1e-6);

    function mouseover() {
      divToolTip
        .transition()
        .attr("class", "padding tooltip")
        .duration(500)
        .style("opacity", 1);
    }

    function mouseout() {
      divToolTip
        .transition()
        .duration(500)
        .style("padding", "0px !important")
        .style("opacity", 1e-6);
    }

    /* Add 'curtain' rectangle to hide entire graph */
    // var curtain = svg.append('rect')
    //     .attr('x', -1 * width)
    //     .attr('y', -1 * height)
    //     .attr('height', height + 25)
    //     .attr('width', width + 80)
    //     .attr('class', 'curtain')
    //     .attr('transform', 'rotate(180)')
    //     .style('fill', '#2e303f')

    /* Optionally add a guideline */
    var guideline = svg
      .append("line")
      .attr("stroke", "#333")
      .attr("stroke-width", 0)
      .attr("class", "guide")
      .attr("x1", 1)
      .attr("y1", 1)
      .attr("x2", 1)
      .attr("y2", height);
  };

  getAvailabilityData(
    regionId,
    countryId,
    cityId,
    commonOD,
    getCabinValue,
    type,
    typeParam
  ) {
    const { gettingYear, gettingMonth } = this.state;
    this.showLoader();
    apiServices
      .getAvailabilityDetails(
        gettingYear,
        gettingMonth,
        regionId,
        countryId,
        cityId,
        commonOD,
        getCabinValue,
        type,
        typeParam
      )
      .then((result) => {
        this.hideLoader();
        if (result) {
          this.setState({
            availModalVisible: true,
            availModalColumn: result[0].columnName,
            availModalData: result[0].rowData,
          });
        }
      });
  }

  rectifyURLValues(regionId, countryId, cityId) {
    if (Array.isArray(regionId)) {
      this.selectedRegion = regionId.join(",");
    } else if (regionId.includes("','")) {
      this.selectedRegion = regionId.split("','").join(",");
      this.selectedRegion = this.selectedRegion.substring(
        1,
        this.selectedRegion.length - 1
      );
    } else {
      this.selectedRegion = regionId;
      this.selectedRegion = this.selectedRegion.substring(
        1,
        this.selectedRegion.length - 1
      );
    }
    if (Array.isArray(countryId)) {
      this.selectedCountry = countryId.join(",");
    } else if (regionId.includes("','")) {
      this.selectedCountry = countryId.split("','").join(",");
      this.selectedCountry = this.selectedCountry.substring(
        1,
        this.selectedCountry.length - 1
      );
    } else {
      this.selectedCountry = countryId;
      this.selectedCountry = this.selectedCountry.substring(
        1,
        this.selectedCountry.length - 1
      );
    }
    if (Array.isArray(cityId)) {
      this.selectedCity = cityId.join(",");
    } else if (regionId.includes("','")) {
      this.selectedCity = cityId.split("','").join(",");
      this.selectedCity = this.selectedCity.substring(
        1,
        this.selectedCity.length - 1
      );
    } else {
      this.selectedCity = cityId;
      this.selectedCity = this.selectedCity.substring(
        1,
        this.selectedCity.length - 1
      );
    }
  }

  storeValuesToLS(regionId, countryId, cityId, commonOD, getCabinValue, data) {
    let region = [];
    let country = [];
    let city = [];
    let cabin = [];

    this.rectifyURLValues(regionId, countryId, cityId);

    if (regionId === "*") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(data)}`
      );
      region.push(data);
      window.localStorage.setItem("RegionSelected", JSON.stringify(region));
    } else if (countryId === "*") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(
          this.selectedRegion
        )}&Country=${data}`
      );
      country.push(data);
      window.localStorage.setItem("CountrySelected", JSON.stringify(country));
    } else if (cityId === "*") {
      // this.props.history.push(
      //   `${this.pathName}?Region=${encodeURIComponent(
      //     this.selectedRegion
      //   )}&Country=${this.selectedCountry}&POS=${data}`
      // );
      // city.push(data);
      // window.localStorage.setItem("CitySelected", JSON.stringify(city));
    } else if (commonOD === "*") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(
          this.selectedRegion
        )}&Country=${this.selectedCountry}&POS=${
          this.selectedCity
        }&${encodeURIComponent("O&D")}=${data}`
      );
      window.localStorage.setItem("ODSelected", data);
    } else if (getCabinValue === "Null") {
      cabin.push(data);
      window.localStorage.setItem("CabinSelected", JSON.stringify(cabin));
    }
  }

  tabClick = (selectedType, outerTab) => {
    if (selectedType === "Route") {
      this.props.history.push("/marketShareRoute");
      this.setState({
        regionId: "*",
        countryId: "*",
        cityId: "*",
        gettingYear: "2021",
        gettingMonth: "December",
      });
    } else {
      var self = this;
      self.sendEvent(
        "2",
        `clicked on ${selectedType} tab`,
        "marketshare",
        "Marketshare Page"
      );
      let { regionId, countryId, cityId, commonOD } = this.state;
      self.setState({
        type: selectedType,
        drillDownData: [],
        drillDownTotalData: [],
        loading2: true,
      });

      if (outerTab) {
        this.setState({ outerTab });
      } else {
        this.setState({ outerTab: "" });
      }
      self.getDrillDownData(
        regionId,
        countryId,
        cityId,
        commonOD,
        selectedType
      );
    }
  };

  ODcellClick = (params) => {
    let {
      gettingMonth,
      regionId,
      countryId,
      cityId,
      commonOD,
      getCabinValue,
      gettingYear,
    } = this.state;

    var column = params.colDef.field;
    var selectedData = params.data.firstColumnName;

    if (column === "CY_AL") {
      window.localStorage.setItem("ODSelected", selectedData);
      this.props.history.push("/topMarkets");
    } else if (column === "Avail") {
      this.getAvailabilityData(
        regionId,
        countryId,
        cityId,
        commonOD,
        getCabinValue,
        "OD",
        selectedData
      );
    }
  };

  agentCellClick = (params) => {
    var column = params.colDef.field;
    var selectedData = params.data.firstColumnName;

    if (column === "firstColumnName") {
      window.localStorage.setItem("Agent", selectedData);
      window.location.href = "/agentAnalysis";
    }
  };

  compartmentCellClick = (params) => {
    let {
      gettingMonth,
      regionId,
      countryId,
      cityId,
      commonOD,
      getCabinValue,
      gettingYear,
    } = this.state;

    var column = params.colDef.field;
    var selectedData = params.data.firstColumnName;

    if (column === "Avail") {
      this.getAvailabilityData(
        regionId,
        countryId,
        cityId,
        commonOD,
        getCabinValue,
        "Compartment",
        selectedData
      );
    } else if (column === "FRCT/Act_A") {
      this.getInfareGraphData(params);
    }
  };

  homeHandleClick = (e) => {
    var self = this;
    self.sendEvent("2", "clicked on Network", "pos", "Pos Page");

    self.setState({
      loading: true,
      loading2: true,
      firstHome: false,
      posMonthDetails: [],
      monthTotalData: [],
      drillDownData: [],
      drillDownTotalData: [],
      toggle: "bc",
    });

    window.localStorage.setItem("RegionSelected", "Null");
    window.localStorage.setItem("CountrySelected", "Null");
    window.localStorage.setItem("CitySelected", "Null");
    window.localStorage.setItem("ODSelected", "Null");

    self.getMarketShareMonthDrillDownData("*", "*", "*", "*");

    bcData = [];
    var newURL = window.location.href.split("?")[0];
    window.history.pushState("object", document.title, newURL);
    // this.props.history.push('/pos')
  };

  listHandleClick = (data, title, selection) => {
    var self = this;
    self.sendEvent("2", "clicked on Drill down list", "pos", "Pos Page");
    let { regionId, countryId, cityId } = this.state;
    var selectedData = data;
    var getColName = decodeURIComponent(title);

    if (
      selectedData.charAt(0) !== "'" &&
      selectedData.charAt(selectedData.length - 1) !== "'"
    ) {
      selectedData = `'${data}'`;
    }
    if (data.includes(",") && getColName !== "Country") {
      selectedData = `'${data.split(",").join("','")}'`;
    }
    self.setState({
      selectedData,
      loading: true,
      loading2: true,
      posMonthDetails: [],
      monthTotalData: [],
      drillDownData: [],
      drillDownTotalData: [],
    });

    if (selection === "List") {
      var indexEnd = bcData.findIndex(function (d) {
        return d.title == title;
      });
      var removeArrayIndex = bcData.slice(0, indexEnd + 1);
      bcData = removeArrayIndex;
      this.changeURLOnListClick(regionId, countryId, cityId, data, getColName);
    } else if (selection === "browserBack") {
      this.onBackPressClearLS(getColName);
    }

    if (getColName === "Region") {
      self.getMarketShareMonthDrillDownData(selectedData, "*", "*", "*");
    } else if (getColName === "Country") {
      selectedData = String.removeQuotes(selectedData);
      selectedData = selectedData.split(",");

      self.getMarketShareMonthDrillDownData(regionId, selectedData, "*", "*");
    } else if (getColName === "POS") {
      self.getMarketShareMonthDrillDownData(
        regionId,
        countryId,
        selectedData,
        "*"
      );
    } else if (getColName === "O&D") {
      self.getMarketShareMonthDrillDownData(
        regionId,
        countryId,
        cityId,
        selectedData
      );
    }
  };

  changeURLOnListClick(regionId, countryId, cityId, selectedData, getColName) {
    this.rectifyURLValues(regionId, countryId, cityId);

    if (getColName === "Region") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(selectedData)}`
      );
      window.localStorage.setItem("CountrySelected", "Null");
      window.localStorage.setItem("CitySelected", "Null");
      window.localStorage.setItem("ODSelected", "Null");
    } else if (getColName === "Country") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(
          this.selectedRegion
        )}&Country=${selectedData}`
      );
      window.localStorage.setItem("CitySelected", "Null");
      window.localStorage.setItem("ODSelected", "Null");
    } else if (getColName === "POS") {
      // this.props.history.push(
      //   `${this.pathName}?Region=${encodeURIComponent(
      //     this.selectedRegion
      //   )}&Country=${this.selectedCountry}&POS=${selectedData}`
      // );
      window.localStorage.setItem("ODSelected", "Null");
    } else if (getColName === "O&D") {
      this.props.history.push(
        `${this.pathName}?Region=${encodeURIComponent(
          this.selectedRegion
        )}&Country=${this.selectedCountry}&POS=${
          this.selectedCity
        }&${encodeURIComponent("O&D")}=${selectedData}`
      );
    }
  }

  onBackPressClearLS(getColName) {
    if (getColName === "Region") {
      window.localStorage.setItem("CountrySelected", "Null");
      window.localStorage.setItem("CitySelected", "Null");
      window.localStorage.setItem("ODSelected", "Null");
    } else if (getColName === "Country") {
      window.localStorage.setItem("CitySelected", "Null");
      window.localStorage.setItem("ODSelected", "Null");
    } else if (getColName === "POS") {
      window.localStorage.setItem("ODSelected", "Null");
    }
  }

  cabinSelectChange = (e) => {
    e.preventDefault();
    const getCabinValue = e.target.value;

    this.setState(
      {
        getCabinValue: getCabinValue,
        cabinSelectedDropDown: getCabinValue,
      },
      () => {
        window.localStorage.setItem(
          "CabinSelected",
          JSON.stringify(getCabinValue)
        );
      }
    );
  };

  onCabinClose() {
    var self = this;
    self.sendEvent("2", "clicked on Cabin drop down", "pos", "Pos Page");
    let { cabinSelectedDropDown } = this.state;

    if (cabinSelectedDropDown.length > 0) {
      this.getDataOnCabinChange();
    } else {
      this.setState({ getCabinValue: "Null" }, () =>
        this.getDataOnCabinChange()
      );
      window.localStorage.setItem("CabinSelected", "Null");
    }
  }

  getDataOnCabinChange() {
    var self = this;
    self.setState({
      loading: true,
      loading2: true,
      posMonthDetails: [],
      monthTotalData: [],
      drillDownData: [],
      drillDownTotalData: [],
    });
    let { regionId, countryId, cityId, commonOD } = this.state;
    self.getMonthDrillDownData(regionId, countryId, cityId, commonOD);
  }

  toggle = (e) => {
    let currency = e.target.value;
    let { regionId, countryId, cityId, commonOD } = this.state;
    this.sendEvent(
      "2",
      "clicked on Currency toggle",
      this.pathName,
      "Pos Page"
    );
    this.setState({ toggle: currency }, () =>
      this.getMonthDrillDownData(regionId, countryId, cityId, commonOD)
    );
  };

  monthChange = () => {
    const e = document.getElementById("monthRange");
    const gettingMonth = e.options[e.selectedIndex].value;
    const gettingYear = e.options[e.selectedIndex].id;
    let { regionId, countryId, cityId, commonOD } = this.state;
    this.sendEvent("2", "clicked on Month dropdown", this.pathName, "Pos Page");
    this.setState({ gettingMonth, gettingYear }, () =>
      this.getMonthDrillDownData(regionId, countryId, cityId, commonOD)
    );
    const range = {
      from: {
        year: parseInt(gettingYear),
        month: window.monthNameToNum(gettingMonth),
      },
      to: {
        year: parseInt(gettingYear),
        month: window.monthNameToNum(gettingMonth),
      },
    };
    window.localStorage.setItem("rangeValue", JSON.stringify(range));
  };

  redirection = (e) => {
    this.sendEvent("2", "clicked on POS/Route drop down", "pos", "Pos Page");
    let name = e.target.value;
    if (name === "Route") {
      this.props.history.push(`/route${Constant.getRouteFiltersSearchURL()}`);
      bcData = [];
    } else {
      this.props.history.push(this.url);
      bcData = [];
    }
  };

  addCellRender(columnDefs) {
    const a = columnDefs.map((c) => {
      if (c.field === "firstColumnName") {
        c["cellRenderer"] = this.alerts;
      }
      if (c.children) {
        c.children = this.addCellRender(c.children);
      }
      return c;
    });
    return a;
  }

  alerts = (params) => {
    const value = params.value;
    const header = params.colDef.headerName;
    var element = document.createElement("span");
    let isAlert = params.data.isAlert;
    isAlert = isAlert !== undefined ? isAlert.toString() : null;
    if (isAlert !== "0" && isAlert !== null) {
      // if (header !== 'Compartment' && header !== 'Agency' && header !== 'Ancillary') {
      var icon = document.createElement("i");
      icon.className = "fa fa-bell";
      icon.onclick = (e) => {
        e.stopPropagation();
        this.getAlertCardsData(header, value);
      };
      element.appendChild(icon);
    }
    element.appendChild(document.createTextNode(params.value));
    return element;
  };

  getAlertCardsData = (header, value) => {
    const {
      gettingMonth,
      gettingYear,
      regionId,
      countryId,
      cityId,
      commonOD,
      getCabinValue,
    } = this.state;
    const userData = JSON.parse(cookieStorage.getCookie("userDetails"));

    let region = Constant.addQuotesforMultiSelect(regionId);
    let country = Constant.getCountryCode(countryId);
    let city = Constant.addQuotesforMultiSelect(cityId);
    let cabin = Constant.addQuotesforMultiSelect(getCabinValue);
    let params = ``;

    if (header !== "Compartment") {
      params = `user_id=${userData.id}`;
    }

    if (header === "Region") {
      params = `${params}&regionId='${encodeURIComponent(value)}'`;
    }
    if (header === "Country") {
      params = `${params}&regionId=${region}&countryId=${Constant.getCountryCode(
        value
      )}`;
    }
    if (header === "POS") {
      params = `${params}&regionId=${region}&countryId=${country}&cityId='${encodeURIComponent(
        value
      )}'`;
    }
    if (header === "O&D") {
      params = `${params}&regionId=${region}&countryId=${country}&cityId=${city}&commonOD='${encodeURIComponent(
        value
      )}'`;
    }

    if (header !== "Compartment") {
      api
        .get(`getallalerts?${params}&infare=low`)
        .then((response) => {
          if (response) {
            if (response.data.response.length > 0) {
              let data = response.data.response;
              this.setState({
                alertData: data,
                alertVisible: true,
              });
            } else {
              this.setState({
                alertData: [],
                alertVisible: true,
              });
            }
          }
        })
        .catch((err) => {});
    }
  };

  //PAX J9 Graph

  getBarchartData = () => {
    const self = this;
    let {
      regionId,
      countryId,
      cityId,
      getGraphPeriod,
      gettingMonth,
      gettingYear,
      period
    } = self.state;

    if (period === 'quarterly'){
      if (gettingMonth === 'Jan - Mar'){
        gettingMonth = 'March'
      }else if(gettingMonth === 'Apr - Jun'){
        gettingMonth = 'June'
      }else if(gettingMonth === 'Jul - Sep'){
        gettingMonth = 'September'
      }else if(gettingMonth === 'Oct - Dec'){
        gettingMonth = 'December'
      }
    }
    this.setState({ isLoading: true, ageGroupData: [] });
    apiServices
      .getMarketShareGraph(
        regionId,
        countryId,
        cityId,
        getGraphPeriod,
        gettingMonth,
        gettingYear
      )
      .then((ageGroupDataResponse) => {
        let response = "";

        if(getGraphPeriod == "monthly"){
            response = leadingMarketShare
        }else if(getGraphPeriod == "yearly"){
            response = leadingMarketShareYear
        }
        this.setState({ isLoading: false });
        if (ageGroupDataResponse) {
          const tableData = response.Data;
          if (tableData.length > 0) {
            this.setState({ ageGroupData: this.normalizeData(tableData) });
          }
        }
      });
  };

  

  normalizeData = (data) => {
    let xValues = data.map(function (d) {
      return d.Airlinename;
    });
    let yValues = data.map(function (d) {
      return window.numberFormat(d.MS_Cy) === 0 ? "0" : window.numberFormat(d.MS_Cy);
    });
    let normalizedData = data;
    // for (var i = 0; i < xValues.length; i++) {
    //   let categoryObj = { x: xValues[i].toUpperCase(), y: yValues[i] };
    //   normalizedData.push(categoryObj);
    // }
    this.setState({
      series: [
        {
          name: "MS_CY : ",
          data: yValues,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: 0,
          colors: ["#fff"],
        },
        plotOptions: {
          bar: {
            borderRadius: 1,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: xValues,
          labels: {
            style: {
              cssClass: "hidegraphlabels",
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "10px",
            color: "#fff",
          },
          x: {
            show: false,
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
      },
    });
    return normalizedData;
  };

  get_Pax_J9_Graph = (selectedData) => {
    this.setState(
      {
        marketShareModalVisible: true,
        cityId: selectedData,
      },

      () => {
        this.getBarchartData();
      }
    );
  };

  getGraphPeriod = (e) => {
    let target = e.target.value;

    this.setState({ getGraphPeriod: target }, () => this.getBarchartData());
  };

  renderMarketShareGraphModal() {
    let {
      regionId,
      countryId,
      cityId,
      getGraphPeriod,
      gettingMonth,
      gettingYear,
    } = this.state;

    let graphHeader =
      getGraphPeriod === "monthly"
        ? "Monthly"
        : getGraphPeriod === "yearly"
        ? "Annual"
        : "Quarter";

    const { isLoading, ageGroupData } = this.state;
    
    return (
      <Modal
        show={this.state.marketShareModalVisible}
        onHide={() => this.setState({ marketShareModalVisible: false })}
        aria-labelledby="ModalHeader"
      >
        <Modal.Header closeButton>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <select
              className="form-control cabinselect pos-route-dropdown"
              onChange={(e) => this.getGraphPeriod(e)}
            >
              <option value="monthly" selected={true}>
                Month
              </option>
              <option value="yearly">Year</option>
              {/* <option value="quarterly">Quarter</option> */}
            </select>
            <h3 style={{ marginLeft: "10%" }}>
              {graphHeader} Leading MarketShare
            </h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            className="x_panel tile"
            style={{ paddingTop: "0px", overflow: "visible" }}
          >
            <div className="x_title reduce-margin"></div>
            {isLoading ? (
              <Spinners />
            ) : ageGroupData.length === 0 ? (
              <h5 style={{ textAlign: "center", margin: "20%" }}>
                No data to show
              </h5>
            ) : (
              <div className="centered-graph">
                <div id="topODAgent"></div>
                <Chart
                  options={this.state.options}
                  series={this.state.series}
                  type="bar"
                  style={{ overflowY: "scroll" }}
                />
              </div>
            )}
          </div>

          {this.state.getGraphPeriod === "monthly" ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h5>
                  {gettingMonth} - {gettingYear}
                </h5>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h5>{gettingYear}</h5>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  getInfareGraphData = (tableParams) => {
    const {
      gettingMonth,
      gettingYear,
      gettingDay,
      regionId,
      countryId,
      cityId,
      commonOD,
      getCabinValue,
      toggle,
      dayLevel,
    } = this.state;
    let region = Constant.addQuotesforMultiSelect(regionId);
    let country = Constant.getCountryCode(countryId);
    let city = Constant.addQuotesforMultiSelect(cityId);
    let cabin = Constant.addQuotesforMultiSelect(getCabinValue);

    let header =
      tableParams.columnApi.columnController.columnDefs[0].children[0]
        .headerName;
    let value = tableParams.data.firstColumnName;

    let params = ``;

    if (header !== "Compartment") {
      params = `gettingMonth=${window.monthNameToNum(
        gettingMonth
      )}&getYear=${gettingYear}&getCabinValue=${cabin}&currency_params=${toggle}`;
    }

    if (header === "Region") {
      params = `${params}&regionId='${encodeURIComponent(value)}'`;
    }
    if (header === "Country") {
      params = `${params}&regionId=${region}&countryId=${Constant.getCountryCode(
        value
      )}`;
    }
    if (header === "POS") {
      params = `${params}&regionId=${region}&countryId=${country}&cityId='${encodeURIComponent(
        value
      )}'`;
    }
    if (header === "O&D") {
      params = `${params}&regionId=${region}&countryId=${country}&cityId=${city}&commonOD='${encodeURIComponent(
        value
      )}'`;
    }
    if (header === "Compartment") {
      params = `currency_params=${toggle}&gettingMonth=${window.monthNameToNum(
        gettingMonth
      )}&getYear=${gettingYear}&getCabinValue='${value}'&regionId=${region}&countryId=${country}&cityId=${city}&commonOD=${encodeURIComponent(
        commonOD
      )}`;
    }

    if (dayLevel) {
      this.showLoader();
      apiServices
        .getInfareDayLevelData(
          gettingYear,
          gettingMonth,
          gettingDay,
          region,
          country,
          city,
          commonOD,
          cabin,
          toggle,
          header,
          value
        )
        .then((result) => {
          this.hideLoader();
          if (result) {
            this.setState({
              infareModalVisible: true,
              infareColumn: result[0].columnName,
              infareData: result[0].rowData,
              infareGraphHeading: `Infare Table of ${value}`,
            });
          }
        });
    } else {
      api
        .get(`infaregraph?${params}`)
        .then((response) => {
          if (response) {
            let graphData = response.data.response;
            let currency = "";
            // let currency = response.data.response[0].currencyData[0].Currency;
            if (graphData.length > 0) {
              this.setState({
                infareData: graphData,
                infareGraphHeading: `Infare Graph of ${value}`,
                infareCurrency: currency,
                infareModalVisible: true,
              });
            } else {
              this.setState({
                infareData: [],
                infareGraphHeading: `Infare Graph of ${value}`,
                infareCurrency: currency,
                infareModalVisible: true,
              });
            }
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong. Please try after some time",
            icon: "error",
            confirmButtonText: "Ok",
          }).then(() => {
            // window.location = '/'
            // cookieStorage.deleteCookie();
          });
        });
    }
  };

  serialize = (params) => {
    var str = [];
    for (var p in params)
      if (params.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
      }
    return str.join("&");
  };

  odsearchfilter = (e) => {
    const { regionId, countryId, cityId, commonOD, type, odsearchvalue } =
      this.state;
    var ODvalue = e.target.value;
    this.setState({ odsearchvalue: ODvalue });
    // var grid =  e.api.setQuickFilter(document.getElementById('filter-text-box').value);
    this.setState({ loading2: true });
    setTimeout(() => {
      this.getDrillDownData(regionId, countryId, cityId, commonOD, type);
    }, 4000);
  };

  monthLevelData() {
    let { regionId, countryId, cityId, commonOD } = this.state;
    window.location.pathname = "/pos";
    setTimeout(() => {
      this.setState({ dayLevel: false }, () =>
        this.getMonthDrillDownData(regionId, countryId, cityId, commonOD)
      );
    }, 1000);
  }

  dayLevelData() {
    let { regionId, countryId, cityId, commonOD } = this.state;
    window.location.pathname = "/posDay";
    setTimeout(() => {
      this.setState({ dayLevel: true }, () =>
        this.getMonthDrillDownData(regionId, countryId, cityId, commonOD)
      );
    }, 1000);
  }

  showLoader = () => {
    $("#loaderImage").addClass("loader-visible");
  };

  hideLoader = () => {
    $("#loaderImage").removeClass("loader-visible");
    $(".x_panel").addClass("opacity-fade");
    $(".top-buttons").addClass("opacity-fade");
  };

  renderTabs = () => {
    let { dayLevel, tabName, gettingYear, type, outerTab, ancillary } =
      this.state;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ul className="nav nav-tabs" role="tablist">
          <li
            role="presentation"
            className={`${ancillary ? "" : "active"}`}
            onClick={() => this.tabClick("Null")}
          >
            <a
              href="#Section1"
              aria-controls="home"
              role="tab"
              data-toggle="tab"
            >
              {tabName}
            </a>
          </li>

          {tabName === "Country" ? (
            ""
          ) : tabName === "City" ? (
            ""
          ) : (
            <li
              role="presentation"
              onClick={() => this.tabClick("Country")}
              className={outerTab === "Country" ? "active" : ""}
            >
              <a
                href="#Section2"
                aria-controls="profile"
                role="tab"
                data-toggle="tab"
              >
                COUNTRY
              </a>
            </li>
          )}

          {tabName === "City" ? (
            ""
          ) : (
            <li role="presentation" onClick={() => this.tabClick("City")}>
              <a
                href="#Section3"
                aria-controls="messages"
                role="tab"
                data-toggle="tab"
              >
                CITY
              </a>
            </li>
          )}

          <li role="presentation" onClick={() => this.tabClick("Route")}>
            <a
              href="#Section4"
              aria-controls="messages"
              role="tab"
              data-toggle="tab"
            >
              ROUTE
            </a>
          </li>
        </ul>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label style={{ marginBottom: "10px", marginRight: "10px" }}>
            Airlines
          </label>
          <CustomDropdown
            name={this.state.AirlineName}
            options={this.state.airlineCollection}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  };

  renderInfareGraphModal() {
    const { dayLevel } = this.state;
    return (
      <Modal
        show={this.state.infareModalVisible}
        onHide={() => this.setState({ infareModalVisible: false })}
        aria-labelledby="ModalHeader"
      >
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">{`${this.state.infareGraphHeading}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dayLevel ? (
            <DataTableComponent
              rowData={this.state.infareData}
              columnDefs={this.state.infareColumn}
            />
          ) : (
            <InfareMultiLineChart
              infareData={this.state.infareData}
              currency={this.state.infareCurrency}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  renderAvailModal() {
    return (
      <DataTableModal
        tableModalVisible={this.state.availModalVisible}
        rowData={this.state.availModalData}
        columns={this.state.availModalColumn}
        header={`Availability Details`}
        loading={this.state.loading3}
      />
    );
  }

  closeTableModal() {
    this.setState({ tableModalVisible: false });
  }
  // handleRangeDissmis = (value) => {
  //   let startDate = this.formatDate(
  //     new Date(value.from.year, value.from.month - 1, 1)
  //   );
  //   let endDate = this.formatDate(new Date(value.to.year, value.to.month, 0));
  //   this.setState({
  //     rangeValuePicker: value,
  //     startDate: startDate,
  //     endDate: endDate,
  //   });
  //   window.localStorage.setItem("rangeValue", JSON.stringify(value));
  // };

  render() {
    const {
      gettingMonth,
      gettingYear,
      cabinOption,
      cabinSelectedDropDown,
      cabinDisable,
      accessLevelDisable,
      outerTab,
      ancillary,
      dayLevel,
      monthRange,
      disableDropDown,
      rangeValuePicker,
    } = this.state;

    const parentStates = {"getAirline":this.state.getAirline,"period":this.state.period,"gettingYear":this.state.gettingYear}
    return (
      <div className="marketShare">
        <Loader />
        <TopMenuBar {...this.props} />
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 top">
            <div className="navdesign" style={{ marginTop: "0px" }}>
              <div
                className="col-md-5 col-sm-5 col-xs-5 toggle1"
                style={{ marginLeft: "10px" }}
              >
                {/* <DatePicker
                  rangeValue={rangeValuePicker}
                  handleRangeDissmis={this.handleRangeDissmis}
                /> */}
                <h5 style={{ marginTop: "10px", marginBottom: "10px", marginRight: "5px", color: "#1ee9d1" }}>Market Share</h5>
                <nav>
                  <ol className="cd-breadcrumb">
                    <div
                      style={{
                        cursor: accessLevelDisable ? "not-allowed" : "pointer",
                      }}
                    >
                      <li
                        className={`${
                          accessLevelDisable ? "breadcrumb-disable" : ""
                        }`}
                        onClick={() => this.homeHandleClick()}
                      >
                        {" "}
                        {"Network"}{" "}
                      </li>
                    </div>
                    {this.state.firstLoadList
                      ? ""
                      : bcData.map((item) => (
                          <div style={{ cursor: "pointer" }}>
                            <li
                              className=""
                              onClick={(e) =>
                                this.listHandleClick(
                                  e.target.id,
                                  item.title,
                                  "List"
                                )
                              }
                              id={item.val}
                              title={`${item.title} : ${item.val}`}
                            >
                              {` > ${item.val}`}
                            </li>
                          </div>
                        ))}
                  </ol>
                </nav>
              </div>

              <div className="col-md-7 col-sm-7 col-xs-7 toggle2">
                <label style={{ marginBottom: "0px", marginRight: "5px" }}>
                  Period
                </label>
                <select
                  className="form-control cabinselect pos-route-dropdown"
                  onChange={(e) => this.period(e)}
                >
                  <option value="monthly" selected={true}>
                    Month
                  </option>
                  <option value="yearly">Year</option>
                  <option value="quarterly">Quarter</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div
              className="x_panel"
              style={{ marginTop: "10px", height: "calc(100vh - 130px)" }}
            >
              <div className="x_content">
                <DataTableComponent
                  rowData={this.state.posMonthDetails}
                  columnDefs={this.state.monthcolumns}
                  loading={this.state.loading}
                  rowClassRules={this.state.posMonthRowClassRule}
                  onCellClicked={(cellData) =>
                    this.monthWiseCellClick(cellData)
                  }
                  frameworkComponents={{
                    customHeaderGroupComponent: MarketShareCustomHeaderGroup,
                  }}
                  pos={true}
                  parentStates={parentStates}
                  ensureIndexVisible={this.state.ensureIndexVisible}
                />
                <TotalRow
                  rowData={this.state.monthTotalData}
                  columnDefs={this.state.monthcolumns}
                  loading={this.state.loading}
                  frameworkComponents={{
                    customHeaderGroupComponent: MarketShareCustomHeaderGroup,
                  }}
                  responsive={true}
                  reducingPadding={true}
                />
                <div
                  className="tab"
                  id="posTableTab"
                  role="tabpanel"
                  style={{ marginTop: "10px" }}
                >
                  {this.renderTabs()}

                  <div className="tab-content tabs">
                    <div
                      role="tabpanel"
                      className={`tab-pane fade in ${
                        ancillary ? "" : "active"
                      }`}
                      id="Section1"
                    >
                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        onCellClicked={(cellData) =>
                          this.regionCellClick(cellData)
                        }
                        loading={this.state.loading2}
                        pos={true}
                      />
                      <TotalRow
                        loading={this.state.loading2}
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        responsive={true}
                        reducingPadding={true}
                      />
                    </div>

                    <div
                      role="tabpanel"
                      className="tab-pane fade"
                      id="Section4"
                    >
                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        onCellClicked={(cellData) =>
                          this.agentCellClick(cellData)
                        }
                        loading={this.state.loading2}
                        pos={true}
                      />
                      <TotalRow
                        loading={this.state.loading2}
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        responsive={true}
                        reducingPadding={true}
                      />
                    </div>

                    <div
                      role="tabpanel"
                      className={`tab-pane fade in ${
                        ancillary ? "active" : ""
                      }`}
                      id="Section5"
                    >
                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        pos={true}
                      />
                      <TotalRow
                        loading={this.state.loading2}
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        responsive={true}
                        reducingPadding={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RBDModal
            tableModalVisible={this.state.tableModalVisible}
            datas={this.state.modalData}
            rowData={this.state.modalCompartmentData}
            columns={this.state.modalCompartmentColumn}
            header={`${this.state.dayLevel ? this.state.gettingDay : ""} ${
              this.state.gettingMonth
            } ${this.state.gettingYear}`}
            loading={this.state.loading3}
            closeTableModal={() => this.closeTableModal()}
          />
          <AlertModal
            alertVisible={this.state.alertVisible}
            alertData={this.state.alertData}
            closeAlertModal={() => this.setState({ alertVisible: false })}
          />

          <div>
            <DataTableModal
              tableModalVisible={this.state.tableModalVisible}
              datas={this.state.modalCompartmentData}
              columns={this.state.modalCompartmentColumn}
              header={`${this.state.gettingMonth}`}
              loading={this.state.loading3}
              closeTableModal={() => this.closeTableModal()}
            />
            {/* <ChartModelDetails
              chartVisible={this.state.chartVisible}
              datas={this.state.modelRegionDatas}
              columns={this.state.modelregioncolumn}
              closeChartModal={() => this.closeChartModal()}
            /> */}
          </div>

          {this.renderAvailModal()}

          {this.renderInfareGraphModal()}

          {this.renderMarketShareGraphModal()}

          {this.renderCityFrequencyModal()}

          {this.renderCityWiseModal()}
        </div>
      </div>
    );
  }
}

const NewComponent = BrowserToProps(MarketShare);

export default NewComponent;
