import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import DataTableComponent from '../Component/DataTableComponent'
import "./component.scss";


class DataTableModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableModalVisible: false,
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.tableModalVisible !== this.props.tableModalVisible) {
            this.setState({ tableModalVisible: newProps.tableModalVisible })
        }
    }


    closeModal() {
        this.setState({ tableModalVisible: false })
    }


    render() {
        const { rowData, columns, loading } = this.props;
        return (
            <div>
                <Modal
                    show={this.state.tableModalVisible}
                    onHide={() => this.closeModal()}
                    aria-labelledby="ModalHeader"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader'>{this.props.header}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'root'}>
                            <DataTableComponent
                                rowData={rowData}
                                columnDefs={columns}
                                loading={loading}
                                autoHeight={'autoHeight'}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}

export default DataTableModal;




